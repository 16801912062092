.home-hero {
    height: 500px;
    background-image: url("/img/home-hero.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;

    &__box {
        width: 40%;
        background-color: #fff;
        height: 310px;

        @media(max-width: 768px) {
            width: 100%;
        }

    }

    &__tabs {
        display: flex;
        width: 100%;
    }

    &__tab {

        display: flex;
        width: 50%;

        a {
            display: block;
            width: 100%;
            padding: 1rem 2rem;
            color: #333;
            text-decoration: none;
            font-size: 16px;
            text-align: center;
            border-bottom: 1px solid #f5f5f5;
        }

        &.active a {
            border-bottom-color: #000;
        }
   
    }

    &__tab-content {
        padding: 20px 50px;
    }

    &__tab-title {
        font-size: 30px;
        color: #000;
        margin: 0 0 20px 0;
    }

    &__tab-text {
        margin-bottom: 20px;
    }

    &__tab-btn {
        padding: 0 15px;
        display: inline-block;
        height: 36px;
        line-height: 36px;
        font-size: 13px;
        border-radius: 2px;
        background: $dark-bg;
        color: $dark-bg-txt;
        transition: .3s ease;

        &:hover, &:focus {
            text-decoration: none;
            background-color: ligten($dark-bg, 10%);
            color: $dark-bg-txt
        }
    }
}