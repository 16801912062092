

// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;


$primaryColor2: #4285f4;
$primaryColor: #4285f4;

$primaryTextColor: #fff;
$darkGrayColor: #f2f2f2;
$secondaryColor: #0093ff;
$bordersColor: rgba(220, 220, 220, 1);
$mainFont:'Raleway';
// $mainFont:'Roboto';

$sitePadding: 15px;

$c-yellow: #FED141;
$c-gray: #727272;
$c-success: #00A87E;
$c-green: #00a65a;

$bg-aqua:  #00c0ef;

$containerWidth: 1170px;


$ribbon-background-color: $primaryColor;
$ribbon-width: 15em;
$ribbon-ends-width: 3.5em;
$ribbon-ends-border-width: 2.8em;


@mixin translateY( $value ){
	-webkit-transform: translateY( $value );
	   -moz-transform: translateY( $value );
	        transform: translateY( $value );
}

@mixin absoluteAll(){
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin verticalMiddleTranslate(){
	top: 50%;
	@include translateY(-50%);
}

@mixin ease( $time ){
	transition: $time ease;
}

@mixin mobile(){
	@media(max-width: 960px){
		@content;
	}
}

@mixin desktop(){
	@media(min-width: 1000px){
		@content;
	}
}

@mixin displacedYPositive()
{
	@include ease(.2s);
	transform: translateY(15px);
}

@mixin resetDisplaceY()
{
	transform: translateY(0);
}

@mixin delay($timing)
{
	transition-delay: $timing;
}



.capitalize{
	text-transform: capitalize;
}
.c-yellow {
	color: $c-yellow;
}
.c-gray {
	color: $c-gray;
}
.c-primary {
	color: $primaryColor;
}
.c-success {
	color: $c-success;
}
.c-secondary {
	color: $secondaryColor !important;
}
.bg-primary {
	background-color: $primaryColor !important;
}
.c-red {
	color: #ff7d7f;
	&.btn {
		&:hover {
			color: darken(#ff7d7f, 10%);
		}
		&.active, &:active {
			box-shadow: none;
		}
	}
}

.fwb {
	font-weight: bold;
}
.mrb {
	margin-bottom: $sitePadding;
}
.mrt {
	margin-top: $sitePadding;
}
.ib {
	display: inline-block !important;
}
.block {
	display: block !important;
}
.w-auto {
	width: auto !important;
}
.mrl-sm {
	margin-left: .5em;
}
.absolute-all {
	@include absoluteAll();
}

.nomargin {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.btn.btn-main{
	background: $primaryColor;
	color: #fff;
	border-color: $primaryColor;
	margin-left: 0;
	border-radius: 0;
	&:hover,
	&:focus{
		opacity: .8;
		background: $primaryColor2;
		color: #fff;		
	}
}

.inline-block-input{
	display: inline-block !important;
	width: auto !important;
}

.bg-green-gradient {
    background: #00a65a !important;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #00a65a), color-stop(1, #00ca6d)) !important;
    background: -ms-linear-gradient(bottom, #00a65a, #00ca6d) !important;
    background: -moz-linear-gradient(center bottom, #00a65a 0, #00ca6d 100%) !important;
    background: -o-linear-gradient(#00ca6d, #00a65a) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ca6d', endColorstr='#00a65a', GradientType=0) !important;
    color: #fff;
}

@mixin flex()
{
	display: flex !important;
	width: auto;
	line-height: 1;	
}

.flex {
	@include flex();
}

.flex-center {
	@include flex();
	align-items: center;
	justify-content: center;
}

@include desktop(){
	.offset2{
		margin-left: 2em;
		margin-right: 2em;
	}
	.offset4{
		margin-left: 4em;
		margin-right: 4em;
	}
}

.entry-title a {
	color: inherit;
}

.cols-by-3 > [class*="col-"]:nth-child(3n+1),
.content-gallery-grid.cols-by-3 > [class*="gallery-item"]:nth-child(3n+1){
	clear: left;
}
.cols-by-2 > [class*="col-"]:nth-child(2n+1){
	clear: left;
}
.cols-by-6 > [class*="col-"]:nth-child(6n+1){
	clear: left;
}
.cols-by-4 > [class*="col-"]:nth-child(4n+1){
	clear: left;
}

.form-group {
	input[type="radio"],
	input[type="checkbox"] {
	    min-height: 13px;
	    min-width: 13px;		
	}
}

.list-type-disc {
	list-style-type: disc;
}

html {
	padding: 0 !important;
	background: #fff !important;
}


.visible-print-only {
	display: none !important;
	@media print {
		display: inline-block !important;
	}
}

@mixin square( $size )
{
	display: inline-block;
	height: $size;
	width: $size;	
	line-height: $size;
}

@mixin round( $size )
{
	@include square( $size );
	border-radius: 50%;
}


$dark-bg: #000;
$dark-bg-txt: #fff;