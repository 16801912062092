// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,700);
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// Rangeslider
//@import "node_modules/ion-rangeslider/css/ion.rangeSlider";
//@import "node_modules/ion-rangeslider/css/ion.rangeSlider.skinFlat";
// PLugins
@import "plugins";
@import "select2";
// icons
@import "drumbun-icons";
@import "material-components";


body {
  font-family: $mainFont;
  overflow-x: hidden;
  background: #fff;
  @include mobile() {
    &.main-nav-open {
      overflow-y: hidden;
      &::before {
        content: '';
        display: block;
        @include absoluteAll();
        background-color: rgba(0, 0, 0, .4);
        z-index: 100;
      }
    }
    &.noscroll {
      @include mobile(){
        overflow-y: hidden;
      }
    }
  }
}

.btn-success {
    background-color: #38c172;
    border-color: #38c172;

    &:hover, &:focus {
      background-color: lighten(#38c172, 15%);
      border-color: ligten(#38c172, 10%);      
    }  
}

.c-primary {
  color: $primaryColor;
}

.error-block {
  color: #a94442;
  text-align: center;
  .big-icon {
    color: inherit;
    border: 1px solid;
    border-color: rgba(#a94442, 0.4);
    height: 50px;
    line-height: 50px;
    width: 50px;
  }
}

figure {
  position: relative;
  img {
    max-width: 100%;
    height: auto;
  }
}

textarea {
  &.tiny {
    height: 100px;
  }
}

label {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

h4 {
  line-height: 1.3;
}

a:focus {
  outline: none;
}

[role="button"],
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* Reset `button` and button-style `input` default styles */
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.control-label {
  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 7px;
  }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background: #f1f1f1;
}

[role="button"] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  // white-space: pre;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

[data-tooltip] {
  position: relative;
}

.hcm-tooltip {
  box-shadow: none;
}

.toggler {
  cursor: pointer;
}

.panel {
  &.panel-default {
    border-color: $bordersColor;
  }
  &.panel--noborder {
    border: none;
  }
}

.pagination > li {
  display: inline-block;
  margin-right: 5px;
}
.home-blog-items {
  padding: 40px 0;
}
.input-group-addon {
  border-radius: 0;
  border-color: #d2d6de;
  background-color: #fff;
}

.image-upload--field {
  display: inline-block;
  position: relative;
  width: 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  @include ease(.3s);
  &:hover {
    opacity: .8;
  }
  &.round {
    height: 120px;
    border-radius: 50%;
    &.success {
      border: 1px solid #d6e9c6;;
    }
  }
  &.square {
    height: 250px;
    width: 100%;
  }
  input {
    display: block;
    @include absoluteAll();
    opacity: 0;
    cursor: pointer;
  }
  .action {
    display: inline-block;
    font-size: 14px;
    color: #a0d871;
    position: absolute;
    bottom: -25px;
    text-align: center;
    i {
        display: inline-block;
        color: #fff;
        background: #a0d871;
        width: 26px;
        height: 26px;
        line-height: 24px;
        border-radius: 50%;        
    }
    .action-text {
      color: #a0d871;
      text-transform: uppercase;
      font-size: 12px;
      display: block;
    }
    &.edit {
      left: calc(100% - 30px);
    }
    &.submit {
      display: none;
      left: -30px;
    }
    &.delete {
      right: 0;
      transform: translate(25%, -70%);
      background: red;
    }
  }
}

.form-group {
  .page-heading & {
    margin-bottom: 0;
  }
  &::after,
  &::before {
    content: ' ';
    display: table;
    clear: both;
  }

  .radio-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    input[type="radio"] {
      margin-right: 7px;
    }
  }
}

.pricing-details {
  @include desktop(){
    display: flex;
    flex-direction: row;
    align-items: center;
    label {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      font-size: 16px;
      .sep {
        margin: 0 5px;
      }
    }
  }
}

.pricing-details-block-departure-location {
  label {
    font-size: 18px;
    font-weight: bold;
  }
}

.wpt-pricing-details {
  .pricing-details {
    margin-bottom: 10px;
  }
  border-bottom: 1px solid $bordersColor;
  margin-bottom: 15px;
  &.disabled {
    display: none;
  }
}

.price-addons-group {
  margin-top: 20px;
  .addon-block{
    margin-bottom: 20px;
    &::before, &::after {
      content: ' ';
      display: table;
    }
  }
  .addable {
    label {
      width: 50%;
      padding-left: 25px;
      position: relative;
      .remove-addon {
        display: inline-block;
        position: absolute;
        @include verticalMiddleTranslate();
        left: 0;
        @include ease(.3s);
        color: #333;
        height: 20px;
        width: 20px;
        line-height:20px;
        text-align: center;
        font-size: 12px;
        border-radius: 50%;
        &:hover {
          background: #ff585a;
          color: #fff;
        }
      }
      @include mobile(){
        width: 100%;
        display: block;
        padding-left: 0;
        padding-right: 30px;
        .remove-addon{
          left: auto;
          right: 0;
        }
      }
    }
    &.last {
      display: none;
      .remove-addon{
        display: none;
      }
    }
  }
}

.price-addons-desc {
  margin-top: 20px;
}

.login-page {

  @include desktop(){
    margin-top: 2.3em;
    padding-top: $sitePadding;
    padding-bottom: $sitePadding;
  }

  .btn {
    border-radius: 0;
    &.login {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  @include mobile() {
    .btn {
      width: 47%;
      box-sizing: border-box;
      &.register {
        float: right;
      }
      &.forgotpassword {
        margin: 10px 0;
        padding: 10px 0;
        text-align: left;
        font-size: 15px;
      }
      &.block {
        width: 100%;
      }
    }
  }
}

.big-icon {
  font-size: 28px;
  color: #ccc;
  &.bordered {
    display: inline-block;
    height: 80px;
    line-height: 80px;
    width: 80px;
    border: 1px solid $bordersColor;
    border-radius: 50%;
  }
  img {
    max-width: 50%;
    height: auto;
  }
}

.img-icon {
  img {
    max-width: 80px;
    height: auto;
  }
  margin-bottom: 30px;
}

.input-group-addon {
  &.success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
  &.danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
}

.input-group--number {
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.input-group--number:not(.input-group--number-style2) {
  position: relative;
  display: flex;
  float: right;
  margin-left: auto;
  input {
    font-size: 16px;
    font-weight: bold;
    max-width: 100px;
    text-align: right;
    &.danger {
      color: $primaryColor;
      border-color: $primaryColor;
    }
  }
  .number-control {
    margin-left: 5px;
    span {
      display: block;
      height: 18px;
      width: 18px;
      line-height: 18px;
      text-align: center;
      border: 1px solid $bordersColor;
      border-radius: 3px;
      font-size: 14px;
      cursor: pointer;
      @include ease(.3s);
      &:hover {
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.flatpickr-wrapper {
  display: block;
}

[data-conditional] {
  display: none;
}

.bold {
  font-weight: bold;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.image-holder {
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}

.primary-colors {
  background: $primaryColor;
  color: $primaryTextColor;
}

.badge-round {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  background: $secondaryColor;
  color: $primaryTextColor;
  border-radius: 50%;
  margin: 0 5px;
  font-size: 14px;
  .user-menu &,
  .chat-users &,
  .profile-usermenu & {
    height: 20px;
    line-height: 20px;
    width: 20px;
    font-size: 11px;
    float: right;
  }
}

.custom-alert {
  padding: 15px 25px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .alert-title {
    margin: 0;
    font-size: 22px;
    font-weight: bold;
    display: inline-block;
    color: #36334A;
    @include mobile() {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  .make-notification {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    float: right;
    background: $primaryColor;
    border-radius: 4px;
    height: 36px;
    line-height: 36px;
    padding: 0 20px;

    i {
      color: #fff;
    }

    @include mobile() {
      font-size: 13px;
      padding: 0 15px;
    }
  }
}

.secondary-button {
  background: $secondaryColor;
  color: $primaryTextColor;
  border: none;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  font-size: 15px;
  box-shadow: none;
  @include ease(.3s);
}

.panel {
  .panel-heading {
    small {
      float: right;
      color: #ccc;
      font-size: 13px;
      a {
        color: inherit;
        @include ease(.3s);
        &:hover {
          color: #333;
        }
      }
    }
  }
}

.navbar {
  margin-bottom: 0;
  border: none;
  padding: 10px 0;
  .user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    margin-left: $sitePadding;
    border: 1px solid rgba(200, 200, 200, .5);
    border-radius: 8px;

    a,
    a:hover {
      text-decoration: none;
    }
    .dropdown-toggle {
      cursor: pointer;
    }
    img {
      max-width: 30px;
      max-height: 30px;
      border-radius: 50%;
      margin-right: $sitePadding;
    }
    .name {
      margin-right: $sitePadding;
    }
  }
  > .open .user {
    background: transparent !important;
  }
  .navbar-nav {
    > li {
      padding: 10px 0;

      > a {
        padding: 0 15px;
        display: inline-block;
        height: 42px;
        line-height: 42px;
        font-size: 17px;
        border-radius: 4px;
        &.button-link {
          @include desktop() {
            background: $dark-bg;
            color: $dark-bg-txt;
            margin-right: 15px;
            transition: .3s ease;
            &:focus,
            &:hover,
            &:active {
              background: lighten($dark-bg, 5%);
              color: $dark-bg-txt;
            }
          }

          &--red {
            background: #e32828;
          }

          i {
            font-size: 16px;
            margin-right: 5px;
          }
        }

        &.bold {
          font-size: 19px;
          color: $primaryColor;
          &.register-btn {
            margin-right: 30px;
          }
        }

      }
    }
  }
  .navbar-brand {
    font-weight: bold;
    font-family: $mainFont;
    color: $primaryColor;
    font-size: 18px;
  }

  .user-menu,
  .shop-menu {
    min-width: 300px;
    padding: 10px 20px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    > li {
      font-size: 15px;
      > a {
        padding: 7px 0;
        @include ease(.2s);
        &:hover {
          border-bottom-color: rgba(200, 200, 200, .4);
          background-color: #fff;
          padding-left: 5px;
        }
      }
      @include ease(.3s);
      @include mobile(){
        &:not(:last-child) a {
          border-bottom: 1px solid rgba(200, 200, 200, .3);
        }
      }
    }
  }

  @include mobile() {
    .home-main-nav {
      position: fixed;
      left: 0;
      bottom: 0;
      top: 0;
      background: #fff;
      transform: translateX(-100%);
      height: 100vh !important;
      width: 85vw;
      @include ease(.3s);
      &.in {
        transform: translateX(0);
      }
      .navbar-nav {
        margin: 0;
        > li {
          a > i {
            display: none;
          }
        }
      }
    }
    .user {
      float: none !important;
      padding-top: 0;
      padding-bottom: 0;
      .dropdown-toggle {
        display: none;
      }
      .user-menu {
        display: block;
        position: relative;
        box-shadow: none;
        border: none;
        padding: 0;
        > li {
          font-size: 18px;
          a {
            padding: 10px 0;
          }
        }
      }
    }
    .shop {
      @include mobile() {
        display: none;
      }
    }
  }

  .shop {
    .dropdown-toggle {
      display: inline-block;
      padding: 15px;
      font-size: 15px;
      cursor: pointer;
    }
  }
}

@include desktop()
{
  .home-main-nav {
    .pick-language {
      margin-top: 11px;
      .lang-switcher {
        border: 1px solid $bordersColor;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        line-height: 30px;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        text-decoration: none;
        text-align: center;
        color: #333;
      }
    }
  }
}

.navbar-logo-img {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 18px;
    color: #333;
    font-weight: 600;
    &:hover {
      text-decoration: none;
    }
  @include mobile() {
    padding-left: 15px;
  }
  img {
    max-height: 40px;
    display: inline-block;
    margin-right: 15px;
  }
  .logo-caption {
    text-transform: uppercase;
    font-size: 25px;
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    color: #5c6f79;    
  }
}


.input-group-transparent-addon {
  position: absolute;
  @include verticalMiddleTranslate();
  color: $primaryColor;
  font-size: 18px;
  left: 10px;
}
.street-picker {
  .form-control {
    padding-left: 15px !important;
    font-size: 13px !important;
  }
  .input-group-transparent-addon {
    left: 5px;
  }
}

.form-group.create-ride-options-select {
  select {
    padding: 0 5px;
    border: 1px solid $bordersColor;
    font-size: 14px;
  }
}

.tc-group{
  &.has-error {
    border: 1px solid #ebccd1;
    padding: $sitePadding;
    background: #ebccd1;
  }
  label {
    margin-bottom: 0;
  }
}

.home-hero {
  position: relative;
  .video-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    > div {
      z-index: 1 !important;
    }
  }
  .welcome-overlay-bg {
    z-index: 2;
  }
  .hero-search {
    @extend .relative;
    height: 90vh;
    background-size: cover;
    background-position: center;
    background-image: url(/img/background-blue.png);


    @media (min-width: 1024px) and (max-width: 1100px) {
      height: calc(100vh - 60px);
    }
    @include mobile() {
      height: 100vh;
    }
    .search-wrapper {
      @extend .absolute;
      top: 50%;
      z-index: 3;
      left: 5%;
      max-width: 650px;
      transform: translateY(-50%);
      padding: 20px 40px;
      background: #fff;
      min-height: 300px;
      border-radius: 14px;
      box-shadow: 0px 1px 6px 5px rgba(200, 200, 200, .6);
      [type="submit"] {
        color: $primaryTextColor;
        @include ease(.3s);
        box-shadow: none;
        &:hover {
          background: darken($primaryColor, 10%);
          box-shadow: 0px -2px 3px rgba($primaryColor, .3);          
        }
      }
      @include mobile() {
        top: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-height: 100vh;
      }
    }

    input[type="text"].maps-autocomplete {
      padding-left: 40px;
    }

    .title,
    .subtitle {
      color:#1850a5;
    }

    .title {
      font-size: 28px;
      margin-bottom: 15px;

      @include mobile() {
        font-size: 44px;
      }

    }
    .subtitle {
      font-size: 15px;
      margin-bottom: 25px;
      margin-top: 0;
      @include mobile() {
        font-size: 24px;
        margin-bottom: $sitePadding * 2;
      }
    }

  }

  @include mobile() {
    max-height: 100vh;
    overflow: hidden;
    .callaction {
      display: none;
    }
  }
}

.callaction {
  background-color: $primaryColor;
  color: $primaryTextColor;
  padding: 20px;
  position: relative;

  &.callaction-bordered::before {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    left: 20px;
    bottom: 20px;
    border: 2px dashed #fff;
  }
  > .container {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  img {
    max-width: 20%;
    display: inline-block;
    margin-right: 30px;
  }
  h3 {
    display: inline-block;
    max-width: 60%;
    font-size: 32px;
    margin: 0;
  }
  .know-more {
    color: $primaryTextColor;
    font-size: 22px;
    position: absolute;
    right: $sitePadding;
    bottom: 25px;
    text-decoration: none;
  }
  .callaction-ribbon & {
    width: $containerWidth + 140px;
    margin-left: auto;
    margin-right: auto;
    border: $sitePadding solid #fff;
    border-top: none;
    &:before, &:after {
      position: absolute;
      top: 100%;
      transform: translateY(-50%);
      border: $ribbon-ends-border-width solid darken($ribbon-background-color, 15%);
      content: "";
      z-index: -1;
    }

    &:before {
      left: -4.5em;
      border-right-width: $ribbon-ends-width;
      border-left-color: transparent;
    }

    &:after {
      right: -4.5em;
      border-left-width: $ribbon-ends-width;
      border-right-color: transparent;
    }
  }
}

.callaction-ribbon {
  background: #f2f2f2;
}

.formprogress {
  display: block;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  .formstep {
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 32px;
    border-radius: 5px;
    background-color: #C6DBFF;
    color: #7777;
    vertical-align: top;
    font-size: 15px;
    text-align: center;

    i {
      font-size: 12px;
    }

    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 120px;
      background-color: #C6DBFF;
      position: absolute;
      left: 100%;
      @include verticalMiddleTranslate();
      @include mobile(){
        width: 60px;
      }
    }

    &.current, &.completed{
      background-color: $primaryColor;
      color: $primaryTextColor;
    }

    &.completed{
      &::after {
        background-color: $primaryColor;
      }
    }
  }
  > span:not(:last-child) {
    margin-right: 100px;
    position: relative;
    @include mobile(){
      margin-right:40px;
    }
  }

  > span {
    &:last-child {
      &::after {
        content: none;
        display: none;
      }
    }
  }

  .create-ride & {
    margin-bottom: 0;
    > span:not(:last-child) {
      margin-right: 150px;
      @include mobile(){
        margin-right: 40px;
      }
      &::after {
        width: 170px;
        @include mobile(){
          width: 60px;
        }
      }
    }
  }

}

.page-heading-container {
  margin-top: 70px;
  margin-bottom: 70px;

  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }
  .page-title {
    margin: 0;
    flex: 1;
    font-size: 24px;
    color: #36334A;
  }

  @include mobile() {
    .container {
      flex-direction: column;
    }

    .page-title {
      margin-bottom: 30px;
    }
  }
}

.form-group{
  position: relative;
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="date"],
  select,
  .button {
    display: inline-block;
    width: 100%;
    line-height: 40px;
    height: 40px;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    color: #333;
    &:focus,
    &:active {
      border-color: $primaryColor;
    }
  }
  textarea:active,
  textarea:focus {
    border-color: $primaryColor;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="date"] {
    padding: 0 20px;
    border: 1px solid rgba(220, 220, 220, 1);
  }

  input[type="radio"]:checked {
    background-color: $primaryColor;
  }

  .button {
    @extend .primary-colors;
    border: none;
    box-shadow: none;
  }

  .addable-field {
    position: relative;
    .remove-field {
      position: absolute;
      right: 10px;
      top: 50%;
      cursor: pointer;
      @include verticalMiddleTranslate();
      font-size: 10px;
    }
  }
  .add-waypoint {
    margin-top: 15px;
  }
}

.create-ride-timepicker {
  label {
    display: block;
  }
  input[type="text"].datetime-picker{
    display: inline-block;
    width: 60%;
    @include mobile(){
      width: 100%;
      margin-bottom: 10px;
    }
  }
  select {
    display: inline-block;
  }
  .h-m-sep {
    @include mobile(){
      display: none;
    }
  }
  .pick-time {
    display: inline-block;
    width: 39%;
    float: right;
    @include mobile(){
      width: 100%;
      float: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    select {
      width: 80px;
      display: inline-block;
      font-size: 14px;
      border-color: $bordersColor;
      padding: 0 4px;
      &.has-error {
       border-color: red;
      }
      @include mobile(){
        width: 46%;
      }
    }
  }

  @media (max-width: 360px) {
    input[type="text"].datetime-picker {
      width: 100%;
    }
    .pick-time {
      width: 100%;
      margin: 10px 0;
      select {
        width: 49%;
      }
    }
  }
}

.waypoints .addable-field {
  @include desktop(){
    max-width: 50%;
  }
}

.flex-v-center {
  display: flex;
  align-items: center;
}

.mb-0{
  margin-bottom: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.create-ride {

  .panel-default {

    > .panel-heading {
      padding: 35px 40px 15px 40px;
      border: none;
      font-size: 22px;

      .panel-title {
        font-size: 22px;
        margin-bottom: 5px;
      }
      .panel-subtitle {
        font-size: 16px;
        color: #555;
      }
    }

    .panel-body {
      padding: 20px 40px 15px 40px;
    }

    .panel-footer {
      padding: 20px 40px 35px 40px;
    }


    @include mobile() {
      > .panel-heading {
        padding: 20px 15px 20px 20px;
      }

      .panel-body {
        padding: 10px 20px 15px 20px;
      }

      .panel-footer {
        padding: 10px 20px 25px 20px;
      }
    }
  }

  .route-values {
    list-style-type: none;
    columns: 2;
    display: none;
    text-align: center;
    margin-bottom: 0;
    > li {
      padding: 10px 0;
      font-size: 16px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:last-child) {
        border-right: 1px solid $bordersColor;
      }
      i {
        font-size: 24px;
        color: #ddd;
        margin-right: 10px;
      }
    }
  }

  .help-block {
    color: #a94442;
  }

  .errors-list {
    list-style: disc;
    padding-left: 20px;
    font-size: 13px;
  }

  .ride-options {
    .panel-body {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    .big-icon {
      display: inline-block;
      font-size: 28px;
      color: $secondaryColor;
      margin-right: $sitePadding;
    }
    .form-group {
      display: inline-block;
      margin-bottom: 0;
      .title {
        margin: 0;
        line-height: 1.6;
      }
      .input-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        input {
          margin-right: 5px;
        }
      }
    }
  }

  .submit-block {
    @include mobile(){
      float: none !important;
      text-align: right;
    }
  }

  .toggle-mobile-map {
    @include desktop() {
      display: none;
    }
  }
  .mobile-map-placeholder{
    display: none;
    @include mobile() {
      display: block;
      margin-top: $sitePadding * 2;
    }
  }
  .map-sidebar {
    @include mobile(){
      .ride-map-panel {
        height: 0;
        display: none;
      }
    }
  }

  .waypoint-agree {
    input[type="radio"] {
      margin-right: 5px;
    }
  }

  .ride-map-panel {

    > .panel-heading{
      padding: 20px;
    }

    .panel-body {
      padding: 15px 0;
    }
    .panel-footer{
      padding: 10px;
    }

    @include mobile(){
      border: none;
      .panel-heading {
        display: none;
      }
      .panel-body {
        padding: 0;
      }
      #mapcanvas {
        min-height: 250px;
        height: 250px;
        width: 100%;
      }
    }
  }

  .booking-type {
    @include mobile() {
      select {
        float: none !important;
      }
    }
  }
  .input-group--number:not(.input-group--number-style2) {
    @include mobile(){
      width: 50%;
      display: flex;
      input{
        max-width: 60px;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;       
        margin: 0 auto;
        text-align: center;
      }
      .number-control {
        span {
          position: absolute;
          height: 34px;
          width: 34px;
          line-height: 34px;          
          border-radius: 50%;
        }
        .sub {
          left: 0;
        }
        .add {
          right: 0;
        }
      }
    }
  }
  .mobile-map-toggle {
    margin: 10px 0;
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .free-seats-row {
    h3 {
      margin: 0;
    }
  }
}

.help[data-tooltip] {
  font-size: 15px;
  font-weight: bold;
}

.page-heading {
  .search-page & {
    margin-bottom: 0;
  }
  padding: 20px 0;
  background: $primaryColor;
  color: $primaryTextColor;
  margin-bottom: 40px;
  .page-title {
    margin: 0;
    @include mobile(){
      font-size: 17px;
    }
  }
}

#mapcanvas,
#mycitiesmap {
  min-height: 350px;
  display: block;
  .search-page & {
    min-height: 70vh;
  }
  &#mycitiesmap {
    min-height: 265px;
    max-height: 265px;
  }
}


.profile {
  margin: 20px 0;
}

/* Profile sidebar */

.profile-sidebar {
  padding: 20px 0 10px 0;
  background: #fff;
}

.profile-userpic img {
  float: none;
  margin: 0 auto;
  width: 35%;
  height: 35%;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.profile-usertitle {
  text-align: center;
  margin-top: 20px;
}

.profile-usertitle-name {
  color: #5a7391;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}

.profile-usertitle-job {
  text-transform: uppercase;
  color: #5b9bd1;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px;
}

.profile-userbuttons {
  text-align: center;
  margin-top: 10px;
  .btn {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    padding: 6px 15px;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.profile-usermenu {
  margin-top: 30px;
  margin-bottom: 30px;
  //ul li {
  //  border-bottom: 1px solid $bordersColor;
  //  &:last-child {
  //    border-bottom: none;
  //  }
  //  a {
  //    color: #93a3b5;
  //    font-size: 14px;
  //    font-weight: 400;
  //    border: none;
  //    i {
  //      margin-right: 8px;
  //      font-size: 14px;
  //    }
  //    &:hover {
  //      background-color: #fafcfd;
  //      color: #5b9bd1;
  //    }
  //  }
  //  &.active {
  //    border-bottom: none;
  //    a {
  //      color: #5b9bd1;
  //      background-color: #f6f9fb;
  //      border-left: 2px solid #5b9bd1;
  //      margin-left: -2px;
  //    }
  //  }
  //}

  @include mobile()
  {
    ul li {
      display: inline-block;
      vertical-align: bottom;    
      a {
        border: none;
        display: inline-block;
        padding: 1.33rem;
        text-align: center;
        vertical-align: bottom;        
        i {
          margin-right: 0;
        }
      } 
      &:last-child {
        border-bottom: 1px solid $bordersColor;
      }      
      &.active {
        a{
          border-left: none;
          border-bottom: 2px solid #5b9bd1;
          margin-left: 0;
        }
      }
      .menu-item-title {
        display: none;
      } 

      .badge-round {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  @media( max-width: 360px ) {
    ul li {
      a {
        padding: 0.8rem;
      }
    }
  }
}

.profile-content {
  background: #fff;
  min-height: 460px;
  .panel-heading {
    padding: 8px 15px;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background: #f9f9f9;
    color: #413D35;
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
    border-color: $bordersColor;
  }
  .dashboard-widgets {
    margin: 0 -5px;
    > div {
      padding: 0 5px;
    }
    .small-box {
      margin-bottom: 10px;
    }
  }
  .error-block {
    text-align: left;
  }
  .confirmations-tab {
    @include mobile(){
      .form-group.flex-center {
        label {
          font-size: 16px;
          margin-bottom: 10px;
        }
        display: block !important;
        .input-group {
          margin-bottom: 15px;
        }
      }
      .label {
        display: inline-block;
        padding: 12px 25px;
        font-size: 15px;
        width: 100%;
        font-weight: normal;
      }
    }
  }
}

.settings-tab-main {
  padding: 20px;
  margin-bottom: 40px;
  border: 1px solid rgba(200, 200, 200, .7);
}

.dashboard-card {
  display: block;
  height: 250px;
  padding: 20px;
  border: 1px solid rgba($bordersColor, 0.5);
  position: relative;
  .card-content {
    @include absoluteAll();
    text-align: center;
    .big-icon {
      font-size: 44px;
      color: $bordersColor;
      margin-bottom: 15px;
    }
    .content-items {
      position: absolute;
      @include verticalMiddleTranslate();
      left: 0;
      right: 0;
      text-align: center;
    }
    .phonenumber {
      color: #777;
      font-size: 15px;
    }
  }
  .confirmation-status {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    i {
      display: inline-block;
      $size: 26px;
      @include round( $size );
      line-height: 26px;
      margin-right: 5px;
    }
  }
}

.settings-tabs {
  margin-bottom: 20px;
}

.search-results {
  min-height: 90vh;
  .custom-alert {
    margin-top: 40px;
    margin-bottom: 20px;
    flex-direction: column;


    .alert-title {
      margin-bottom: 20px;
      color: #777;
    }

    .make-notification{
      display: block;
      width: 100%;
      text-align: center;
    }
  }
  .page-heading {
    margin-bottom: 20px;
  }
  .results-count {
    padding: 15px 0;
    font-size: 16px;
  }
  .results {
    @include ease(.4s);
    &.loading {
      opacity: .6;
    }
  }
}

.results-wrapper {
  border-top: 1px solid $bordersColor;
}

@include mobile() {
  .search-page,
  .search-results {
    .page-heading {
      .form-group {
        margin-bottom: 10px;
        input[type="text"] {
          width: 100% !important;
        }
        .secondary-button[type="submit"] {
          display: table;
          margin: 10px auto 0 auto;
        }
      }
    }
  }
}

.ride-entry {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  border-bottom: 1px solid $bordersColor;
  padding: 15px 0;

  .departure-datetime,
  .price {
    font-size: 26px;
    color: $primaryColor;
    display: block;
    font-weight: bold;
    margin-top: 0;
    .desc {
      font-size: 18px;
      color: #333;
      font-weight: normal;
      display: block;
      @include mobile() {
        display: none;
      }
    }
    @include mobile() {
      font-size: 20px;
    }
  }
  .free-seats {
    font-size: 15px;
    margin-top: 5px;
    b {
      font-size: 18px;
      font-weight: bold;
    }
    @include mobile() {
      font-size: 12px;
      margin-top: 0;
    }
  }
  aside {
    text-align: right;
    @include mobile() {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
      align-items: flex-start;
    }
  }
  .overlay-link {
    background: rgba(255, 255, 255, 0);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include ease(.3s);
    &:hover {
      background-color: rgba(122, 77, 21, .05);
    }
  }

  @include mobile() {
    & {
      flex-direction: column-reverse;
    }

    header,
    .ride--author {
      width: 100%;
    }

    header {
      margin-bottom: $sitePadding;
    }

    .departure-location,
    .arrival-location {
      font-size: 15px;
      line-height: 1.8;
    }
  }
  .waypoints {
    margin-bottom: 0;
    > li {
      display: none;
      &:nth-child(-n+3) {
        display: block;
      }
      &:nth-child(3){
        &::after {
          content: '';
          border: 1px dashed $bordersColor;
          display: block;
          width: 60px;
          margin-left: 26px;
        }
      }
    }
  }
}

.departure-location,
.arrival-location,
.waypoint-location {
  display: flex;
  align-items: center;
  padding: 5px 0;
  &::before {
    content: '\e819';
    font-family: "drumbun-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    /* font-size: 120%; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 18px;
    color: $secondaryColor;
  }
}

.waypoint-location::before {
  color: #FED330;
}

.arrival-location::before {
  color: #F44257;
}

.meta-value.waypoints {
  > i.icon-location{
    color: #FED330;
    font-size: 18px;
  }
}

.profile-card {
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
.avatar {
max-width: 70px;
border-radius: 50%;
margin-right: $sitePadding;
}
figcaption {
display: flex;
flex-direction: column;
.name {
  font-size: 16px;
  font-weight: bold;
}
}
i {
color: yellow;
}
@include mobile() {
.avatar {
  max-width: 50px;
}
}
}

.single-ride {

.ride-actions {
margin-bottom: 20px;
.btn {
  $size: 38px;
  height: $size;
  line-height: $size;
  border-radius: 0;
  padding: 0 20px;
  margin-right: 10px;

  @media (max-width: 768px){
    width: 100%;
    margin-bottom: 10px;
  }
}

.bookings--link{
  position: relative;

  span {
    position: absolute;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    width: 26px;
    text-align: center;
    background: #ffd400;
    border-radius: 50%;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
}
}

.comments-list {
padding-left: 65px;
> li {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
.comment {
  background: #F5F5F5;
  padding: 15px 20px;
  border-radius: 40px;
  font-size: 15px;
  line-height: 1.6;
  color: #777777;
  border-top-left-radius: 0;
}
}

.leave-comment {
padding: $sitePadding;
border-top: 1px solid $bordersColor;
.profile-card {
  .avatar {
    max-width: 50px;
  }
  figcaption {
    .name {
      font-size: 15px;
    }
  }
}
textarea {
  height: 80px;
}
@include mobile() {
  .btn {
    padding: 10px 20px;
    font-size: 14px;
  }
}
}

@include mobile() {
.panel-footer.actions {
  .btn {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
}
}

aside {
position: relative;
}
.ride-main-panel {
.panel-body {
  position: relative;
}
}
.goback {
display: block;
margin-bottom: 20px;
}
.title {
display: inline-block;
font-size: 20px;
margin-bottom: 20px;
line-height: 1.5;
i {
  font-size: 13px;
  margin: 0 5px;
}
a {
  font-size: 15px;
  outline: none;
  @include mobile(){
    display: block;
    margin: 5px 0;
  }
}
}
.panel-heading {
padding: 10px 15px;
margin: 0;
font-size: 20px;
font-weight: normal;
line-height: 22px;
color: #333333;
background: #fff;
border-bottom: none;
}
.ride-route-details {
list-style: none;
padding: 0;
li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
  font-size: 14px;
  clear: both;
  @include mobile() {
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 10px;
    &:not(:last-child) {
      border-bottom: 1px solid $bordersColor;
    }
  }
  > div {
    display: inline-block;
    padding: 5px 0;
    &.meta-name {
      width: 30%;
    }
    &.meta-value {
      width: 70%;
      &.departure-location,
      &.arrival-location {
        line-height: 1;
      }
      &.waypoints > i:last-child {
        display: none;
      }

      &.bold--meta {
        color: $dark-bg;
        font-weight: bold;
      }
    }

    @include mobile() {
      &.meta-name,
      &.meta-value {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
      }
      &.meta-name {
        font-size: 17px;
        font-weight: bold;
      }
    }
  }
  .meta-value {
    small {
      margin-left: 5px;
      font-style: italic;
      text-transform: capitalize;

    }
  }
}

.route-values {
  span {
    margin-right: 7px;
    i {
      margin-right: 3px;
      @include mobile() {
        $size: 32px;
        display: inline-block;
        font-size: 18px;
        width: $size;
        height: $size;
        line-height: $size;
        background: $primaryColor;
        color: $primaryTextColor;
        border-radius: 50%;
        text-align: center;
      }
    }
    @include mobile() {
      display: block;
      margin-bottom: 10px;
    }
  }
}

}

.contact-driver {
padding: $sitePadding;
border-top: 1px solid $bordersColor;
color: #413D35;
font-size: 14px;
border-radius: 3px;
figure {
  margin-bottom: 10px;
  img {
    max-height: 50px;
    max-width: 50px;
    margin-right: 10px;
  }
  figcaption {
    .name {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.contact {
  @include desktop() {
    margin-left: 60px;
  }
}

@include mobile()
{
  .btn {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
  }
}
}

.ride-meta {
padding: 0;
list-style-type: none;
border-bottom: 1px solid $bordersColor;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
li {
  flex: 1;
  text-align: center;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    max-width: 56px;
    opacity: .7;
  }

  &:first-child {
    border-right: 1px solid $bordersColor;
  }
}
.value {
  font-size: 40px;
  color: #413D35;
  display: block;
  @include mobile() {
    font-size: 30px;
  }
}
.desc {
  display: block;
}
}

.booking-passengers {
text-align: center;
ul {
  margin-top: 10px;
  li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}
.seat-status {
  $size: 36px;
  display: block;
  box-sizing: border-box;
  width: $size;
  height: $size;
  border: 2px dashed #ddd;
  border-radius: 50%;
  background-size: cover;
  @include mobile() {
    width: $size + 10px;
    height: $size + 10px;
  }
}
}
.booking-request {
background: #f9f9f9 !important;
border-top: 1px solid $bordersColor;
.booking-acceptance {
  margin-bottom: 10px;
}
select {
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  margin: 0;
  background: #fff;
  box-shadow: inset 0 -1px #ddd;
  color: #413D35;
  font-size: 14px;
  padding-left: 7px;
}
input[type="submit"], .login-btn {
  box-sizing: border-box;
  padding: 10px 15px;
  border: 0;
  border-radius: 3px;
  color: #fff;
  background-color: #38c172;
  text-align: center;
  text-decoration: none;
  box-shadow: inset 0 -2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: block;
  width: inherit;
  height: 54px;
  font-size: 16px;
  line-height: 18px;
  vertical-align: middle;
  width: 100%;

  &[disabled="disabled"] {
    opacity: .8;
    cursor: default;
  }
}
.login-btn {
  display: inline-block;
  line-height: 35px;
}
}
.sidebar .panel-body {
padding: 0;
}
.booking-passengers,
.booking-request,
.ride-meta > li {
padding: 15px;
}

.sidebar {
.driver .panel-body {
  padding: 15px;
}
}
&.status-cancelled {
.ride-route-details {
  opacity: .5;
  filter: blur(3px);
}
.status-cancelled {
  @include absoluteAll();
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: $primaryColor;
}
}

.user-booking-status {
display: flex;
flex-direction: row;
align-items: center;
}

.option--two-passengers {
.value {
  display: inline-block;
  font-size: inherit;
}
.big-icon {
  font-size: 20px;
  color: $secondaryColor;
  margin-right: 10px;
}
}

.international-expenses-block {
padding: $sitePadding;
border-top: 1px solid $bordersColor;
.expenses-label {
  font-size: 16px;
  margin-bottom: $sitePadding;
}
}
}
.bookings-tab-content {
margin-top: $sitePadding * 2;
.panel-body {
padding: 0;
}
}


.single-ride {
.confirm-ride-finished {
.ride-title {
  display: none;
}
}
}

.cancel-ride-modal {
.modal-body {

}
.cancel-ride {
display: inline-block;
float: left;
color: #fff;
border: none;
background-color: #38c172;
font-size: 14px;
text-decoration: none;
}
}
.notifications-panel {
padding-top: 0;
}
.notifications {
list-style-type: none;
padding: 0;

.notification {
position: relative;
padding: 15px 0;
border-bottom: 1px solid $bordersColor;
display: flex;
flex-direction: row;
align-items: center;
.user-avatar {
  position: relative;
  max-width: 30px;
  display: inline-block;
  margin-right: 10px;
  img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
  }
}
.overlay-link {
  @include absoluteAll();

}
.notification-content {
  display: block;
  width: 100%;
  font-size: 13px;
  a {
    text-decoration: none;
  }
  .actions {
    margin: 10px 0;
    > a {
      margin-right: 10px;
    }
  }
  .created,
  .view {
    float: right;
    font-size: 12px;
    color: #ccc;
    a {
      color: inherit;
      text-decoration: none;
      @include ease(.3s);
      &:hover {
        color: #333;
      }
    }
  }
  .view {
    margin-right: 5px;
  }
}
}
}

.hcm-noty-theme {
margin-bottom: 5px;
}

.live-notification {
display: flex;
justify-content: flex-start;
align-items: flex-start;
padding: $sitePadding;
background-color: rgba(#222d32, 0.93);
color: #fff;
position: relative;
> i {
$size: 34px;
height: $size;
width: $size;
line-height: $size;
text-align: center;
background: $primaryColor;
color: $primaryTextColor;
display: inline-block;
display: table;
border-radius: 50%;
font-size: 16px;
}
.instant-chat-toggler {
display: block;
@include absoluteAll();
}

.notification-content {
display: inline-block;
padding-left: 10px;
.notification-header {
  font-size: 1.1em;
}
}

}

.chat {
.panel-body {
display: flex;
flex-direction: row;
padding: 0 15px;
}
ul {
list-style-type: none;
&.chat-users {
  background: rgba(200, 200, 200, .3);
  margin-bottom: 0;
  padding: 0;
  li {
    padding: 10px;
    border-bottom: 1px solid $bordersColor;
    cursor: pointer;
    &.current {
      background: #e9f7da;
    }
  }
}
&.chat-messages {
  max-height: 500px;
  min-height: 300px;
  overflow-y: scroll;
  padding: $sitePadding;
  &.unloaded::before {
    content: 'Выберите контакт из списка';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fcfcfc;
    color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > li {
    text-align: left;
    .message {
      padding: 5px 15px;
      background: #daffb1;
      border-radius: 15px;
      display: inline-block;
      max-width: 70%;
      .sent-time {
        display: block;
        font-size: 10px;
      }
    }
    &.mine {
      text-align: right;
      .message {
        background: #def1ff;
      }
    }
  }
}
}
}

.datetime-picker {
-webkit-appearance: none;
}

.user-profile {
.sidebar {
padding: $sitePadding;
border: 1px solid $bordersColor;
border-radius: 3px;
}
.section {
padding-left: 30px;
padding-top: 50px;
@include mobile(){
  padding: 30px 0 0 0;
}
}
.profile-card.lg {
img {
  max-width: 150px;
  max-height: 150px;
}
.name {
  font-size: 28px;
  font-weight: bold;
}
.age {
  font-size: 26px;
}
@include mobile(){
  img {
    max-width:70px;
    max-height:70px;
  }
  .name {
    font-size: 18px;
  }
  .age {
    font-size: 15px;
  }
}
}
.user-description {
padding: 15px;
background: rgba($primaryColor, 0.06);
border-radius: 3px;
font-size: 22px;
position: relative;
margin: 25px 0;
}

.details--block {
.title {
  margin-right: 10px;
}
.title,
.user-preferences {
  display: inline-block;
  > li {
    max-width: 40px;
  }
}
}

.average-feedback{
border-radius: 3px;
background: #f2f2f2;
padding: 15px;
}
}

.widget {
border-bottom: 1px solid $bordersColor;
padding-bottom: 15px;
margin-bottom: 15px;
.title {
font-size: 18px;
font-weight: bold;
}
ul {
margin-bottom: 0;
li {
  line-height: 1.8;
  font-size: 15px;
}
&.confirmations {
  li.confirmed {
    font-weight: bold;
    color: #00A87E;
  }
  li.unconfirmed {
    text-decoration: line-through;
    color: #999;
  }
}
}
&.user-car {
img {
  max-width: 60px;
  margin-right: 10px;
  border-radius: 50%;
}
}
&.footer-widget {
border: none;
margin-bottom: 0;
padding-bottom: 0;
}
.widget-links {
a {
  padding: 3px 0;
  display: block;
  color: $primaryColor;
  font-size: 15px;
  text-decoration: none;
  @include ease(.2s);
  &:hover {
    color: $c-gray;
  }
}
}
}

.notifications-modal {
.ride-entry {
padding-top: 0;
padding-bottom: 0;
flex-direction: column;
margin-bottom: $sitePadding;
padding-bottom: $sitePadding;
}

.flatpickr-wrapper {
margin-top: 15px;
}

.form-group.submit {
margin: $sitePadding 0;
width: 100%;
.btn {
  display: table;
  margin: 0 auto;
  padding: 7px 15px;
}
}
.form-group {

@include desktop() {
  display: inline-block;
  width: 49%;
  float: left;
}
}

.loader {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
}
}

.booking-page {

.form-group {
label {
  font-size: 15px;
  margin-bottom: 10px;
}
select, input, textarea {
  border-color: $bordersColor;
}

textarea {
  max-height: 80px;
}
}

.ride-meta {
border-bottom: 1px solid $bordersColor;
li {
  i {
    margin-right: 10px;
    font-size: 16px;
  }
  padding-bottom: 10px;
}
}

.widget.activity {
border-bottom: none;
margin: 0;
padding: 0;
}

.cost {
display: block;
text-align: center;
font-size: 16px;
padding: $sitePadding;
background: #f5f5f5;
border: 1px solid rgba(200, 200, 200, .7);
margin: 25px 0;
@include ease(.3s);
i {
  display: inline-block;
  font-size: 28px;
  color: $c-success;
}

  .as-icon {
    margin-bottom: 15px;

    img {
      max-width: 32px;
      height: auto;
    }
  }


&.loading{
  opacity: .8;
  i {
    &::before {
      content: '\e82e';
    }
    animation: spinner .6s linear infinite;
  }
}
&.error {
  .calculated {
    display: none;
  }
  .price-error {
    display: block !important;
  }
}

&.local {
  .international-expenses-block {
    display: none;
  }
}

.international-expenses-block {
  ul li {
    font-size: 13px;
    .value {
      font-size: 13px;
    }
  }
}

.value {
  font-weight: bold;
  font-size: 18px;
}
}

@include mobile(){
.submit-fg {
  float: none!important;
  text-align: center;
  .btn-main {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0;
  }
}
.form-group {
  select {
    margin-bottom: 10px;
  }
}
}
}

.form-group .location-input--wrapper {
display: block;
position: relative;
input[type="text"] {
padding-left: 35px;
font-size: 15px;
}
&::before {
content: '';
display: block;
margin-right: 10px;
height: 17px;
width: 17px;
border: 3px solid $primaryColor;
border-radius: 50%;
border-color: #9bd22c;
position: absolute;
@include verticalMiddleTranslate();
left: 10px;
}
&.origin::before {
border-color: #00A87E;
}
&.destination::before {
border-color: $primaryColor;
}

&.waypoint {
margin: $sitePadding 0;
&::before {
  border-color: $secondaryColor;
  //cursor: move;
}
}
}

.sortable {
display: block;
position: relative;
}

.mycar {
label {
display: block;
}

.select2-container {
display: block;
width: 100% !important;
}

.form-group {
select {
  height: 28px;
  line-height: 28px;
}
}
}

.user-preferences {
  margin: $sitePadding 0;
  display: flex;
  flex-direction: column;
  > li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    img {
      max-width: 32px;
      margin-right: 10px;
    }
  }
}

.ride-driver--sidebar{
  .card-widgets {
    margin: 20px 0;
  }
}

.pub--booking-actions {
  margin: 15px 0;
  text-align: center;
}

.buttons-actions--list {
  margin: 15px 0;
}

.pub-profile-preferences {
  display: block;
  margin-bottom: 30px;
}

.booking--hint {
  font-size: 11px;
  margin: 10px 0;
}


.pub-profile-user-car {
  display: flex;
  align-items: center;
  padding: 50px;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .widget-title {
    margin-bottom: 20px;
  }

  .avatar {
    $size: 80px;
    width: $size;
    height: $size;
    border-radius: 50%;
    margin-bottom: 10px;

    img {
      width: $size;
      height: $size;
      border-radius: 50%;
    }

    .model {
      font-size: 15px;
    }
  }
}

.modal {
&.transparent {
color: rgba(255, 255, 255, 1);
// text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);

.modal-header {
  padding-left: 0;
  padding-right: 0;
  border: none;
  position: relative;
  button {
    color: inherit;
    font-size: 22px;
    opacity: 1;
    position: absolute;
    @include verticalMiddleTranslate();
    right: 0;
    outline: none;
  }

  .route-values {
    font-size: 14px;
  }
}
.modal-content {
  box-shadow: none;
  background: transparent;
  border: none;
}
.modal-body {
  padding: 0;
}
}
}

.route-table {
  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    @include mobile(){
      width: 26px;
      height: 26px;
    }
  }

a {
  text-decoration: none;
  color: inherit;
}

th {
  &.location {
    font-size: 13px;
    max-width: 150px;
  }
  .ride-city {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    top: -60px;
    .departure-location::before,
    .arrival-location::before,
    .waypoint-location::before {
      width: 20px;
      height: 20px;
    }

    i {
      margin-left: -3px;
      background: #fff;
      border-radius: 50%;
    }

    @include mobile(){
      height: 55px;
      top: -52px;
    }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 7px;
    height: 100%;
    width: 6px;
    background: #4d4d4d url(../img/bg-road-dash.png) repeat-y 50% 3px;
    top: 61%;
  }
}

&.arrival .ride-city {
  height: 25px;
  top: -37px;

  &::after {
    content: none;
  }
}

.departure-datetime, .arrival-datetime {
  height: 20px;
  line-height: 20px;
}

}

@include mobile(){
.user .name {
  display: none;
}
.ride-city {
  font-size: 11px;
}
}

td {
text-align: center;
border-color: transparent !important;
&.driver {
  background: #eff8fd;
}
&.seat {
  background: #E6F7F2;
}

i {
  font-size: 22px;
  @include mobile() {
    font-size: 16px;
  }
}
}

small {
display: block;
font-weight: normal;
margin-left: 5px;
text-transform: capitalize;
}
}

.user-feedbacks {
.profile-card {
img {
  max-width: 50px;
}
margin-bottom: 15px;
}
}

.leave-feedback {
text-align: left;
label {
font-weight: bold;
font-size: 14px;
}

.leave-feedback-heading {
margin-top: 0;
margin-bottom: 15px;
}

.rating-picker,
.rating-readonly-picker {
margin: 10px 0;
i {
  display: inline-block;
  height: 26px;
  width: 26px;
  line-height: 26px;
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  background: rgba(200, 200, 200, .7);
  color: rgba(255, 255, 255, .5);
  @include ease(.3s);
  cursor: pointer;
  &:not(.disabled):hover,
  &.active {
    background: $primaryColor;
    color: $primaryTextColor;
  }
}
}

.feedback-comment {
margin: 10px 0;
font-size: 14px;
}
}

.carousel-item {
outline: none;
}

.bookings--search-wrapper {
select.control {
display: inline-block;
max-width: 120px;
height: 28px;
line-height: 28px;
}
}

.booking--loop-item {

display: flex;
position: relative;
padding-top: $sitePadding;
padding-bottom: $sitePadding;
border: 1px solid $bordersColor;
margin-bottom: $sitePadding * 2;

header {
display: block;
position: relative;
&::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: -$sitePadding;
  bottom: -$sitePadding;
  width: 1px;
  background: $bordersColor;
}
}

.user-details {
display: flex;
align-items: flex-start;
justify-content: space-between;
flex-direction: row;
.booking-details {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  li {
    font-size: 14px;
    color: $c-gray;
    &:not(:last-child) {
      margin-right: 5px;
      &::after {
        content: ',';
      }
    }
  }
}
}

.booking--comment {
margin-bottom: 5px;
}

.profile-card {
margin-bottom: $sitePadding;
img {
  max-width: 50px;
}
}

.booking--user-msg {
> div {
  padding: 5px 0;
}
}

.booking-location {
padding: 0 0 0 20px;
position: relative;
i {
  position: absolute;
  @include verticalMiddleTranslate();
  left: 0;
  @include square(20px);
  line-height: 20px;
  font-size: 16px;
  background: #fff;
}
&.booking-departure-location {
  margin-bottom: 25px;
  i {
    color: $primaryColor;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 10px;
    height: 30px;
    border-left: 2px dotted $bordersColor;
  }
}
&.booking-arrival-location {
  i{
    color: $secondaryColor;
  }
}
}

.details {
text-align: center;
display: block;
margin-top: 15px;
}

@include mobile()
{
flex-direction: column;
header, section {
  width: 100%;
  float: none;
}
header {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $bordersColor;
}
.user-details {
  flex-direction: column;
}
}
}

.btn-material,
.btn-main,
.booking--loop-item .actions .btn{
border: 1px solid rgba(200, 200, 200, .2);
border-radius: 0;
transition: .3s ease;
box-shadow: none;
margin-right: 7px;
padding: 7px 14px;
&:hover {
  box-shadow: 0px -2px 3px rgba(200, 200, 200,.4 );
}
}

.order-by {
  .order-toggle {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    cursor: pointer;
    background-color: #f5f5f5;
    margin-right: -5px;
    text-decoration: none;
    &.date {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    &.price {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      margin-right: 0;
    }
    &.active {
      background: #ddd;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    }

  @include mobile() {
    padding: 7px 13px;
    font-size: 15px;
  }
}

.order-text {
@include mobile() {
  float: left;
  line-height: 2.3em;
}
}

.dropdown-menu {
  min-width: 58px;
  padding: 0;
  margin: 2px 0 0 0;
  > li > a {
      padding: 3px;
      @include ease(.3s);
      &:hover {
        background: #59BEE6;
        color: #fff;
      }
    }
  }
}

.footer {
  padding: 30px 0;
  background: #000;
  color: #fff;
  .footer-bar {
    color: #fff;
    text-align: center;
    font-size: 14px;
    padding: 10px 0;
    margin-top: 20px;
  }

  .widget .widget-links a {
    color: #fff;
    transition: .3s ease;

    &:hover {
      color: rgba(255, 255, 255, .8);
    }
  }
}

.icon-boxes--section {
padding-top: 40px;
padding-bottom: 40px;
.title  {
margin-top: 0;
}
}

.icon-box {
position: relative;
display: block;
text-align: center;
img {
max-width: 170px;
height: auto;
margin-bottom: 25px;
height: 150px;
width: auto;
}
.big-icon {
font-size: 58px;
color: $primaryColor;
margin: 10px 0;
}
.icon-title {
font-size: 20px;
font-weight: bold;
margin-bottom: $sitePadding;
}
.desc {
font-size: 17px;
line-height: 1.8;
color: $c-gray;
}
&.dark-bg {
.big-icon {
  display: inline-block;
  height: 120px;
  line-height: 120px;
  width: 120px;
  margin-bottom: $sitePadding;
  background: #464235;
  border-radius: 50%;
  color: #fff;
}
margin-bottom: $sitePadding;
}
}

.confirmation-modal {

.big-icon {
margin: $sitePadding * 2 auto;
color: $c-success;
border-color: $c-success;
}

.title {
margin-bottom: 20px;
}

.form-group {
padding-left: 2em;
padding-right: 2em;
}

.modal-footer {
padding-right: 3em;
}

.request-code {
display: block;
}
}

.page-404 {
padding-top: 80px;
padding-bottom: 80px;
.title {
font-size: 56px;
font-weight: bold;
}
.subtitle {
font-size: 18px;
margin-bottom: $sitePadding * 2;
}
.gohome {
display: inline-block;
height: 40px;
line-height: 40px;
padding: 0 30px;
background: $primaryColor;
color: $primaryTextColor;
border-radius: 25px;
font-size: 15px;
@include ease(.3s);
text-decoration: none;
&:hover {
  opacity: .8;
}
}
}

.label-with-checkbox {
padding-left: 20px;
input[type="checkbox"] {
float: left;
margin-left: -20px;
}
margin-bottom: $sitePadding;
}

[data-step] {
display: none;
&[data-step="1"] {
display: block;
}
}

[data-step-hide="1"] {
display: none;
}

.product--loop-item {
margin-bottom: $sitePadding * 2;
&.product--loop-item-grid {

.image-holder {
  margin-bottom: $sitePadding;
  &:hover {
    .coupon-amount {
      transform: translateY(-50%) scale(1.08) rotate(-3deg);
    }
  }

  .price {
    position: absolute;
    top: $sitePadding;
    left: -$sitePadding;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 25px;
    background: $primaryColor;
    color: $primaryTextColor;
    font-size: 22px;
    font-weight: bold;
    &::after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 12px 0;
      border-color: transparent darken($primaryColor, 15%) transparent transparent;
      position: absolute;
      left: 0;
    }
  }
}
.section-overlay {
  position: absolute;
  bottom: 2em;
  background: rgba(0, 0, 0, .7);
  padding: 10px $sitePadding;
  left: 0;
  right: 0;
  color: #fff;
  @include mobile(){
    padding: 7px $sitePadding;
  }
  .overlay-caption {
    font-size: 18px;
    @include mobile(){
      font-size: 16px;
    }
  }
}

.coupon-amount {
  $size: 110px;
  @include mobile(){
    $size: 70px;
  }
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: $sitePadding;
  top: 50%;
  transform: translateY(-50%);
  @include round( $size );
  text-align: center;
  background: $primaryColor;
  color: $primaryTextColor;
  border: 2px solid rgba(255, 255, 255, .8);
  padding: 10px;
  @include ease(.15s);
  .amount {
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
    @include mobile(){
      font-size: 25px;
    }
  }
  .currency {
    font-weight: bold;
    font-size: 12px;
    @include mobile(){
      font-size: 12px;
    }
  }
}

}
header a {
text-decoration: none;
}
.entry-title {
font-size: 28px;
color: #000;
&:hover {
  text-decoration: none;
}
@include mobile(){
  font-size: 24px;
}
}
.subtitle {
font-size: 18px;
font-weight: normal;
margin-bottom: $sitePadding;
}

.order {
display: inline-block;
height: 46px;
line-height: 46px;
padding: 0 25px;
font-size: 22px;
background: $primaryColor;
color: $primaryTextColor;
@include ease(.3s);
&:hover {
  background: $secondaryColor;
}
}

&.product--loop-item-thumbnail {
position: relative;
a {
  text-decoration: none;
}
.image-holder {
  overflow: hidden;
  .overlay-link {
    background: rgba(0, 0, 0, .25);
    @include ease(.3s);
  }
}
&:hover {
  .overlay-link {
    background: rgba(darken($primaryColor, 15%), .2);
  }
}
.entry-title {
  line-height: 1.5;
}
.price {
  width: 200px;
  height: 35px;
  line-height: 35px;
  position: absolute;
  top: 20px;
  right: -67px;
  transform: rotate(45deg);
  color: #fefefe;
  text-align: center;
  background: $secondaryColor;
  font-size: 0.867em;
}
header {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 15px;
  left: 0;
  right: 0;
  text-align: center;
  .entry-title {
    font-size: 18px;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
  }
}

.product-pricing-details {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  padding: 7px 35px;
  background: $primaryColor;
  color: $primaryTextColor;
  display: inline-block;
  border-top-left-radius: 30px;
  font-size: 18px;
  del {
    margin: 0 5px;
    font-size: 0.750em;
    text-decoration: line-through;
  }
  ins {
    text-decoration: none;
  }

}
}
}

.product-price-ribbon {
// position: absolute;
// top: $sitePadding;
// left: -$sitePadding;
display: inline-block;
height: 36px;
line-height: 36px;
padding: 0 25px;
background: $primaryColor;
color: $primaryTextColor;
font-size: 22px;
font-weight: bold;
// &::after {
//   content: '';
//   display: inline-block;
//   width: 0;
//   height: 0;
//   border-style: solid;
//   border-width: 0 15px 15px 0;
//   border-color: transparent #2da0b4 transparent transparent;
//   position: absolute;
//   left: 0;
// }
}

.explanation-list {
list-style-type: none;
padding-left: 0;
.explanation-item {
position: relative;
counter-increment: section;
margin-bottom: $sitePadding * 2;
> i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 24px;
}
.explanation-title {
  margin-top: 0;
  &::before {
    content: counter(section) ".";
    margin-right: 5px;
  }
}
.explanation-content {
  display: inline-block;
  @include desktop() {
    float: left;
    max-width: 50%;
  }
  @include mobile() {
    margin-bottom: $sitePadding;
  }
}
.explanation-hint {
  display: inline-block;
  padding: $sitePadding;
  color: #333;
  font-size: 13px;
  @include desktop() {
    float: right;
    margin-top: -30px;
    width: 40%;
  }
}
}
}

.template--trust-and-security {
  min-height: 90vh;
}


.become-sponsor-button{
  display: inline-block;
  height: 46px;
  line-height: 46px;
  padding: 0 22px;
  background: #EB5777;
  color: #fff;
  font-weight: bold;
  border: 1px solid rgba(#EB5777, .5);
  text-decoration: none;
  border-radius: 30px;
  transition: .3s ease;
  img {
    max-width: 32px;
    height: auto;
    margin-right: 14px;
  }
  &:hover {
    text-decoration: none;
    background: darken(#EB5777, 5%);
    color: #fff;
  }
}


//trust and security
.trust-and-security--item {
  display: flex;
  align-items: center;
  margin-bottom: $sitePadding * 2;
  .item-title {
    color: $primaryColor;
    font-size: 22px;
    margin-top: 0;
  }

  .image-holder {
    text-align: center;
    img {
      max-width: 140px;
      height: auto;
    }
  }
}

// Genuine info
.genuine-info {
list-style-type: none;
.genuine-item {
padding-left: 50px;
margin-bottom: $sitePadding * 2;
.info {
  padding-left: $sitePadding * 2;
}
.genuine-icon {
  color: $primaryColor;
  font-size: 44px;
  position: absolute;
  top: 0;
  left: 0;
}
.genuine-title {
  color: $primaryColor;
  font-size: 22px;
  margin-top: 0;
}
}
}

.custom-lg-tabs {
border-bottom-color: rgba($dark-bg, .2);
> li {
> a {
  padding: $sitePadding;
  font-size: 20px;
  font-weight: bold;
  color: $dark-bg;
  @include mobile() {
    font-size: 12px;
    padding: 5px;
  }
  span {
    font-size: 13px;
    font-weight: normal;
    display: block;
    @include mobile() {
      display: none;
    }
  }
}
&:hover,
&.active {
  > a,
  > a:hover,
  > a:focus {
    background: $dark-bg;
    color: $dark-bg-txt;
  }
}
}

+ .tab-content {
  .tab-pane {
    padding-top: $sitePadding * 2;
  }
}

  &.custom-lg-tabs--center {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.template--road-safety {
&.page-with-image-header .overlay-header {
margin-bottom: $sitePadding * 2;
}
.custom-lg-tabs {
+ .tab-content .tab-pane {
  padding-top: 0;
}
}
.tab-title {
margin-bottom: $sitePadding;
}
}

// page with image header
.page-with-image-header {
.overlay-header {
position: relative;
display: block;
height: 250px;
background-size: cover;
background-position: center center;
color: #fff;
text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
flex-direction: column;
margin-bottom: 4em;
text-align: center;
> * {
  position: relative;
}
.big-icon {
  color: inherit;
  font-size: 44px;
}
&::before {
  content: '';
  display: block;
  @include absoluteAll();
  background: rgba(0, 0, 0, .3);
}
@include mobile() {
  height: 70vh;
  .title {
    text-align: center;
    font-size: 26px;
    line-height: 1.7;
  }
}
}
&.blog-post .overlay-header {
height: 350px;
}
}

.checklist-section {
.checklist-item {
position: relative;
padding-left: 50px;
.checklist-title {
  font-size: 16px;
  .checklist-icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 28px;
    color: $primaryColor;
  }
  small {
    display: block;
    color: $c-gray;
    font-size: 12px;
    margin-top: 5px;
  }
}
.checklist-inputs {
  label {
    display: block;
  }
}
}
}

.tips-list {
list-style-type: none;
padding-left: 0;
> li {
counter-increment: section;
position: relative;
padding-left: 60px;
margin-bottom: $sitePadding * 2;
&::before {
  display: inline-block;
  content: counter(section);
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: 36px;
  line-height: 36px;
  border: 1px solid $primaryColor;
  font-size: 16px;
  color: $primaryColor;
  text-align: center;
  border-radius: 50%;
}
}
}

// Print corrections
@media print {
.page-with-image-header {
.overlay-header {
  background: transparent;
  color: #333;
  height: 200px;
  margin-bottom: 0;
  .big-icon {
    display: none;
  }
}
.custom-lg-tabs {
  display: none;
}

.print {
  display: none;
}
}
.checklist-item {
.checklist-icon {
  display: none;
}
}
.footer {
display: none;
}
}

.taxonomy-link {
display: inline-block;
height: 24px;
line-height: 24px;
padding: 0 $sitePadding;
text-decoration: none;
color: $primaryTextColor;
@include ease(.3s);
&:hover {
color: $primaryTextColor;
background: rgba($primaryColor, 0.7);
text-decoration: none;
}
}

.my-booking {
.booking-ride-panel {
h3 {
  margin-top: 10px;
}
}
}

.blog-post {
.post-title {
font-size: 52px;
margin-top: 20px;
margin-bottom: 35px;
}

.share-block {
position: absolute;
bottom: 0;
left: 0;
right: 0;
text-align: center;
padding: 10px 0;
&.share-block--lg {
  > a {
    &:not(:last-child) {
      border-right: 1px solid rgba(255, 255, 255, .2);
    }
  }
  .share-item {
    padding: 0 $sitePadding;
    font-size: 28px;
    color: rgba(255, 255, 255, .8);
    text-decoration: none;
    @include ease(.3s);
    &:hover {
      color: rgba(255, 255, 255, 1);
      border-color: rgba(255, 255, 255, .25);
    }
  }
}
}
.the-content {
position: relative;
padding-right: 10%;
}
.profile-card {
position: absolute;
top: 0;
left: 0;
}
}

.blog-archive {
.archive-title {
text-align: center;
margin-bottom: 50px;
}
}

.blog-post--loop-item {
.entry-title {
font-size: 26px;
margin-bottom: $sitePadding;
margin-top: $sitePadding;
color: #333;
a {
  color: inherit;
  text-decoration: none;
}
}
.entry-excerpt {
font-size: 14px;
line-height: 1.8;
}
&.grid-post {
text-align: center;
.image-holder {
  margin-bottom: 30px;
}
margin-bottom: 30px;
}
}

.knowledgebase-section {
.faq-search-box {
[type="text"]{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[type="submit"] {
  line-height: 38px;
  height: 38px;
  padding: 0 50px;
  font-size: 16px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  @include mobile(){
    padding: 0 25px;
  }
}
margin-bottom: 50px;
}
.faq-category {
font-size: 28px;
border-bottom: 1px solid $bordersColor;
margin-bottom: 10px;
padding-bottom: 10px;
}
.faq-entry--list {
list-style-position: inside;
> li {
  font-size: 15px;
  &.with-content {
    margin-bottom: 20px;
    .title {
      font-size: 18px;
    }
    .excerpt {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
}
}

.faq-post {
.the-content {
padding: 20px;
border: 1px solid $bordersColor;
font-size: 15px;
line-height:1.7;
ul {
  list-style: disc;
  padding-left: 20px;
}
}
}

.maintenance {
div.phpdebugbar {
display: none !important;
}
}

.maintenance-page {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.single-product {
.the-content {
background: #fff;
border-top: 3px solid $primaryColor;
padding-left: 20px;
padding-top: 30px;
padding-bottom: 30px;
}
.product-content--container {
margin-top: -10em;
@include mobile()
{
  margin-top: -6em;
}
}
&.page-with-image-header.blog-post .overlay-header {
height: 50vh;
}

.product--price-lg-block {
text-align: center;
.actual-price-placeholder {
  height: 50px;
  line-height: 50px;
  background: $primaryColor;
  color: $primaryTextColor;
  font-size: 28px;
  del {
    font-size: .8em;
    opacity: .8;
  }
  ins {
    text-decoration: none;
    margin-left: 10px;
  }

}
.price-label-decorator {
  height: 30px;
  line-height: 30px;
  background: $secondaryColor;
  color: $primaryTextColor;
  font-size: 14px;
}
}

.profile-card {
position: relative;
}
.products-by-company {
.title {
  margin-bottom: 20px;
}
}

}

.icon-list--horizontal {
> li {
width: 33%;
display: inline-block;
@include mobile()
{
    width: 100%;
}
}
&.cols-by-2 {
> li {
  width: 45%;
  @include mobile()
  {
      width: 100%;
  }
}
}
.horizontal-icon-item {
display: flex;
align-items: center;
padding-left: 50px;
position: relative;
text-align: left;
padding-top: 20px;
padding-bottom: 20px;
margin-right: 20px;
@include ease(.3s);
&:hover {
  opacity: .8;
}
[class*="icon-"] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 30px;
  color: $primaryColor;
}

}
}

.content-gallery {
&.content-gallery-grid {
&.cols-by-3 {
  .gallery-item-wrap {
    float: left;
    width: 31%;
    margin-right: 10px;
    margin-bottom: 10px;
    img {
      max-width: 100%;
    }
  }
}
@include mobile()
{
  &[class*="cols-by-"] {
    .gallery-item-wrap{
      width: 47%;
      clear: none;
    }
  }
}
}
}

.product-purchase-scroller {
display: none;
@include round(40px);
line-height: 40px;
background: $secondaryColor;
color: $primaryTextColor;
text-align: center;
font-size: 15px;
position: absolute !important;
right: 20px;
top: 20px;
box-shadow: 1px 2px 5px rgba(20, 20, 20, .4);
i {
margin-left: -3px;
}
@include mobile(){
display: inline-block;
}
}

.product-place-order-widget {
padding: 20px 30px;
background: $primaryColor;
color: $primaryTextColor;
margin: 30px 0;
position: relative;
.title {
font-size: 18px;
margin: 0 0 15px 0;
line-height: 1.8;
text-align: center;
b {
  font-size: 22px;
  display: block;
}
}
.submit {
position: absolute;
left: 50%;
bottom: 0;
transform: translate(-50%, 50%);
padding: 7px 25px;
border-radius: 0;
background: $secondaryColor;
color: #fff;
@include ease(.15s);
&:hover {
  color: #fff;
  background: darken($secondaryColor, 2%);
  padding: 7px 30px;
}

@include mobile(){
  padding: 10px 25px;
  font-size: 15px;
}
}
}


.product-tabs {
margin-top: -83px;
margin-left: -20px;
border-bottom: none;
@include mobile(){
margin-top: -62px;
margin-left: 0;
}
> li {
margin-right: 7px;
> a {
    &:hover {
      background: #fff;
    }
    height: 50px;
    line-height: 51px;
    margin: 0;
    padding: 0 35px;
    color: #333;
    font-size: 0.933em;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    border: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: #fff;
    transition: background 0.2s ease-in-out, height 0.2s ease-in-out, margin 0.2s ease-in-out;
    @include mobile()
    {
      height: 30px;
      line-height: 31px;
      font-size: 0.8em;
      padding: 0 20px;
    }
}

&.active {
  > a, > a:hover, a:focus {
    height: 53px;
    line-height: 57px;
    margin-top: -3px;
    border: none;
    background: $primaryColor;
    color: $primaryTextColor;
    @include mobile()
    {
      height: 32px;
      line-height: 35px;
    }
  }
}
}

+ .tab-content {
padding-top: 20px;
}
}

.checkout-page {
.has-error {
color: #dd4b39;
}
.variable-product-checkout {
.product-discount-selector {
  text-align: left;
}
}

.page-heading .page-title {
font-size: 26px;
}
.cannot-purchase, .info-block {
background: rgba($secondaryColor, 0.1);
padding: $sitePadding;
margin: $sitePadding 0;
}
}

.products-disclaimer {
border: 1px solid $bordersColor;
padding: $sitePadding;
margin-top: $sitePadding * 2;
}

.checkout-product-item {
.product-title {
margin-top: 0;
}

}


.shop-icons-bar {
padding: 20px 0;
background: #f5f5f5;
margin-bottom: $sitePadding * 2;
.shop-icon-el {
$color: #465157;
i {
  font-size: 20px;
  color: $color;
}
.textarea {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  margin-left: 7px;
  .icon--title {
    font-size: 14px;
    font-weight: 600;
    color: $color;
    line-height: 19px;
    margin: 0;
  }
  .icon--desc {
    font-size: 13px;
    font-weight: 300;
    color: #839199;
    line-height: 19px;
  }
}
@include mobile(){
  margin-bottom: 15px;
}
}
}

.shop-sidebar {
border: 1px solid;
background-color: transparent;
border-color: #dae2e6;
border-radius: 2px;
margin-bottom: $sitePadding * 2;
.sidebar-heading{
background: #f5f5f5;
line-height: 1;
padding: 14px 15px;
border-radius: 5px 5px 0 0;
margin: 0;
border-bottom: 1px solid #ddd;
color: #465157;
font-size: 16px;
font-weight: 700;
text-transform: uppercase;
}


}
.shop-categories {
$linksColor: #465157;
margin-bottom: 0;
> li{
> a {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  padding: 13px 15px;
  display: block;
  border-top: 1px solid #ddd;
  text-decoration: none;
  @include ease(.3s);
  color: $linksColor;
  &:hover {
    background: $primaryColor;
    color: $primaryTextColor;
  }
}
&:first-child {
  > a {
    border-top: 0;
  }
}
}
}

.products-slider {
position: relative;
margin-bottom: $sitePadding * 2;
.product-slider--item  {
height: 400px;
background-size: cover;
background-position: center;
position: relative;
outline: none;
.item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include absoluteAll();
  background: rgba(0, 0, 0, .2);
  padding: 20px;
  a, a:hover{
    text-decoration: none;
  }
  .product-price-ribbon{
    @include displacedYPositive();
    @include delay(.1s)
  }
  .product-title {
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    margin-bottom: $sitePadding * 2;
    font-size: 30px;
    line-height: 1.5;
    @include displacedYPositive();
    @include delay(.25s);
    @include mobile(){
      font-size: 26px;
    }
  }
  .order {
    padding: 6px 23px;
    border-radius: 7px;
    font-size: 16px;
    @include displacedYPositive();
    @include delay(.35s);
  }
}

&.slick-current {
  .product-price-ribbon {
    @include resetDisplaceY();
  }
  .order{
    @include resetDisplaceY();
  }

  .product-title {
    @include resetDisplaceY();
  }
}
}
.slick-dots {
display: block;
bottom: 0;
position: absolute;
text-align: center;
width: 100%;
.custom-dot {
  display: inline-block;
  height: 7px;
  width: 7px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  @include ease(.2s);
  &:hover {
    background: $primaryColor;
  }
}
> li {
  display: inline-block;
  &:not(:last-child) {
    margin-right: 7px;
  }
  &.slick-active {
    .custom-dot {
      background: $primaryColor;
    }
  }
}
}
}

.product-recommended-ribbon{
position: absolute;
top: $sitePadding * 2;
right: $sitePadding * 2;

i{
display: inline-block;
font-size: 16px;
height: 30px;
line-height: 30px;
width: 30px;
background: $c-success;
color: #fff;
border-radius: 50%;
}
.recommended-text {
// display: inline-block;
// background: #4fe24f;
color: #fff;
// padding: 0 5px;
// margin-left: -10px;
// border-radius: 7px;
font-size: 12px;
}
}


.purchase-status-alert {
display: block;
padding: 15px;
border-left: 3px solid $primaryColor;
margin: 15px 0 30px 0;
font-size: 16px;
.details {
  display: block;
  font-size: 13px;
  margin-top: 5px;
}
}

.purchases-table-loop {
.image-holder {
max-width: 60px;
}
}

.purchase-details {
.purchase-metas {
li {
  display: block;
  padding: 3px 0;
  font-size: 15px;
  .name {
    font-weight: bold;
    width: 160px;
    display: inline-block;
  }
}
}
}

.shop-hero-search {
margin-bottom: 30px;
position: relative;
$sizes: 40px;
@mixin sameHeights()
{
height: $sizes;
line-height: $sizes;
}

label {
font-size: 12px;
color: #CCCCCC;
text-transform: uppercase;
}

.search-input {
display: block;
width: 100%;
@include  sameHeights();
border: 1px solid $bordersColor;
&.large-search-input-title {
  padding: 0 $sitePadding;
}
}
.search-group{
vertical-align: top;
@include desktop(){
  display: inline-block;
  margin-right: $sitePadding;
  &.title {
    width: 52%;
  }
  &.cat {
    width: 20%;
  }
  &.price-limit {
    width: 15%;
  }
}
@include mobile(){
  margin-bottom: 15px;
}
}

.select2-container {
margin-right: $sitePadding;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__arrow{
@include  sameHeights();
}
.select2-container--default .select2-selection--single{
border: 1px solid $bordersColor;
border-radius: 0;
margin-top: -1px;
}
.btn.btn-main {
background-color: #4285f4;
border-color: darken(#4285f4, 10%);
border-radius: 0;
@include sameHeights();
height: 38px;
padding: 0 20px;
@include mobile(){
  box-sizing: border-box;
  width: 100%;
}
@include desktop(){
  float: right;
  margin-top: 25px;
}
&:hover {
  background-color: lighten(#4285f4, 10%);
  border-color: #4285f4;
}
}
}

.btn-primary {
background-color: #4285f4;
border-color: darken(#4285f4, 10%);
&:hover {
  background-color: lighten(#4285f4, 10%);
  border-color: #4285f4;
}
}

.categories-widget {
.categories-mobile-toggler {
display: none;
}
@include mobile(){
.categories-list {
  display: none;
}
.categories-mobile-toggler {
  display: inline-block;
  float: right;
}
}
}
.collapsible-toggler {
.toggler-icon{
@include ease(.3s);
}
&.open {
.toggler-icon {
  transform: rotate(-180deg);
}
}
}

.collapsible-sm {
@include mobile(){
display: none;
}
}


.autocomplete-list-wrapper {
position: absolute;
z-index: 999;
background: #fff;
width: 100%;
border: 1px solid $bordersColor;
box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, .2);

.autocomplete-list {
margin-bottom: 0;
> li {
  background: #fff;
  cursor: pointer;
  padding: 5px 15px;
  @include ease(.3s);
  &:not(:last-child) {
    border-bottom: 1px solid $bordersColor;
  }
  &:hover {
    background: #eeeeee;
  }
  @include mobile() {
    padding: 7px 15px;
  }
}
}
&.autocomplete-products {
.autocomplete-list{
  >li {
    a {
      color: #333;
      text-decoration: none;
      display: block;
    }
    .image-block {
      max-width: 30px;
      display: inline-block;
      vertical-align: top;
      @include mobile(){
        max-width: 50px;
      }
    }
    .title-block {
      display: inline-block;
      margin-left: 10px;
      width: calc(100% - 50px);
      @include mobile(){
        width: calc(100% - 70px);
      }
    }
  }
}
}

.notfound{
padding: 15px;
}
}

.confirm-ride-finished-block{
.title {
font-size: 22px;
margin-bottom: 15px;
}
.ride-title {
font-size: 18px;
margin-bottom: 25px;
}
}

.confirm-ride-finished-block{
&:not(.default) {
padding: 50px;
margin-bottom: 30px;
text-align: center;
border: 2px dashed rgba($primaryColor, 0.2);
background: rgba($primaryColor, 0.06);
}
}

.instant-chat-wrap {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 430px;
  height: 455px;
  background: #fff;
  z-index: 999;
  box-shadow: -1px -1px 10px 1px rgba(0, 0, 0, .3);
  @include ease(.3s);
  transform: translateY(110%);


  &.loading,
  &.loaded {
    transform: translateY(0);
  }

  &.closed {
    .toggle-closed-chat {
      transform: scale(1);
      @include ease(.3s);
      transition-delay: .2s;
    }
  }
  @include mobile()
  {
    top: 0;
    bottom: 0;
    height: 100vh;
    left: 0;
    max-width: 100%;
    z-index: 9999;
  }
}

.instant-chat-component {

  .chat-body-wrap {
    position: relative;
  }
  .chat-header, .chat-body, .chat-footer {
    padding: $sitePadding;
  }
  .chat-header {
    color: #333;
    position: relative;
    background: #fff;
    border-bottom: 1px solid rgba(200, 200, 200, .5);

    .profile-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      img {
        max-width: 40px;
        margin-right: 0;
        margin-bottom: 10px;
      }

      .profile--card-head {
        .name {
          font-size: 13px;
          color: #555;
        }

        .age {
          font-size: 11px;
          color: #777;
        }
      }
    }
  }
  .chat-body {
    overflow-y: scroll;
    height: 270px;
    @include mobile(){
      height: 76vh;
    }
  }
  .chat-footer {
    background: #f5f5f5;
    .form-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 0;
      textarea {
        display: inline-block;
        width: 100%;
        height: 40px;
        overflow: hidden;
        resize: none;
        border-radius: 0;
        @include mobile(){
          height: 60px;
          font-size: 17px;
        }
      }

      @include mobile() {
        .btn {
          height: 58px;
          line-height: 58px;
          padding: 0 20px;
          border: none;
        }
      }


      .btn {
        border-radius: 0;
      }
    }
    @include mobile()
    {
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      @include ease(.2s);
    }
  }
  .messages {
    > li {
      padding: 18px 20px;
      display: inline-block;
      border-radius: 8px;
      font-size: 15px;
      width: 70%;
      animation: messagePop .25s ease-in-out;
      margin-bottom: 10px;
      word-wrap: break-word;
      box-shadow: 0 0 25px rgba(34, 69, 128, 0.15);

      @include ease(.3s);
      &.mine {
        float: right;
        background: $primaryColor;
        color: #fff;
      }
      &.their {
        text-align: left;
        background: #fff;
      }
    }
  }

  .icons-block {
    position: absolute;
    display: inline-block;
    top:15px;
    right: 15px;
    i {
      color: #777;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .toggle-closed-chat {
    display: inline-block;
    font-size: 28px;
    background-color: $primaryColor;
    color: $primaryTextColor;
    @include round(50px);
    line-height: 50px;
    text-align: center;
    position: fixed;
    bottom: 115%;
    right: 20px;
    transform: scale(0);
    }
  .new-messages-count {
    position: absolute;
    top: 0;
    right: 0;
    @include round(20px);
    line-height: 20px;
    background: $secondaryColor;
    color: #fff;
    font-size: 12px;
    &.pulse {
      animation: pulse .6s infinite;
    }
  }

  .loaded & + .loader {
    opacity: 0;
  }

  .user-phonenumber {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    @include ease(.3s);
    &.displace {
      opacity: 0;
      transform: translateX(110%);
    }
    i {
      display: inline-block;
      height: 80px;
      line-height: 80px;
      width: 80px;
      text-align: center;
      border: 1px dashed #ccc;
      border-radius: 50%;
      font-size: 32px;
      color: #ccc;
    }
    .phone {
      margin: 20px 0;
      font-size: 15px;
      letter-spacing: 1px;
    }
  }
}

@keyframes spinner {
to {transform: rotate(360deg);}
}

@keyframes spinner2 {
0% {transform: rotate(0);}
50%{transform: rotate(180deg);}
100%{transform: rotate(360deg);}
}

.loader:before {
content: '';
box-sizing: border-box;
position: absolute;
top: 50%;
left: 50%;
width: 20px;
height: 20px;
margin-top: -10px;
margin-left: -10px;
border-radius: 50%;
border: 2px solid #ccc;
border-top-color: #333;
animation: spinner .6s linear infinite;
opacity: 0;
}

@keyframes messagePop{
0%{
transform: scale(0) translateY(15px);
}
100%{
transform: scale(1) translateY(0);
}
}

@keyframes pulse{
0% {
tranform: scale(1);
}
50% {
transform: scale(1.2);
}
100% {
transform: scale(1);
}
}


@keyframes pulseMiddle{
0% {
tranform: translateY(-50%) scale(1);
}
50% {
transform: translateY(-50%) scale(1.1);
}
100% {
transform: translateY(-50%) scale(1);
}
}

.rich-chat-wrapper{
&.loading {
.instant-chat-component{
  opacity: 0;
}
background: #fcfcfc;
}
@include mobile(){
&.closed{
  .instant-chat-component {
    @include ease(.3s);
    transform: translateX(100%);
  }
}
}
}
.instant-chat-rich {
.chatted-users {
background: $primaryColor;
> li {
  padding: 15px;
  color: #fff;
  background: $primaryColor;
  border-bottom: 1px solid rgba(255, 255, 255, .4);
  position: relative;
  @include ease(.3s);
  cursor: pointer;
  &:hover, &.chat-active, &.chat-active-emu {
    background: lighten($primaryColor, 10%);
  }

}

.profile-card {
  img {
    max-width: 38px;
  }
  figcaption {
    .name {
      font-size: 14px;
    }
  }
}

.messages-count {
  position: absolute;
  @include verticalMiddleTranslate();
  right: 15px;
  @include ease(.2s);
  @include round(24px);
  line-height: 24px;
  &.hidden {
    transform: scale(0);
  }
  &.pulse {
    animation: pulseMiddle .6s infinite;
  }
}

@include mobile()
{
  $marginTop: 80px;
  position: fixed;
  top: $marginTop;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
}
}

.col-md-4 {
padding-right: 0;
}
.col-md-8 {
padding-left: 0;
}

.instant-chat-component{
.chat-header {
  @include desktop(){
    display: none;
  }
}
.chat-body {
  height: 350px;
}
.chat-footer {
  position: relative;
}
@include mobile()
{
  $marginTop: 80px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 9999;
  .chat-body {
    height: 74vh;
  }
}
}
}

.noedit-alert {
.title {
margin-top: 0;
margin-bottom: 15px;
}

.sub {
margin-bottom: 15px;
}
}

.feedback-item-block {
margin-bottom: 15px;
padding-bottom: 15px;
border-bottom: 1px solid $bordersColor;
padding-left: 60px;
position: relative;
.feedback-author {
display: inline-block;
margin-right: 15px;
position: absolute;
top: 0;
left: 0;
figure {
  width: 40px;
  img {
    border-radius: 50%;
  }
}
}
.feedback-content {
display: inline-block;
@include desktop(){
  width: 60%;
}
.feedback-karma {
  &::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  &.feedback-karma-4 {
    &::before {
      background: #007D57;
    }
  }
  &.feedback-karma-5 {
    &::before {
      background: #69ca82;
    }
  }
  &.feedback-karma-3 {
    &::before {
      background: #e4af37;
    }
  }
  &.feedback-karma-2,
  &.feedback-karma-1{
    &::before {
      background: #e43737;
    }
  }

  .karma-label {
    font-weight: bold;
    font-size: 16px;
  }
}
}

.feedback-comment {
font-size: 15px;
.feedback-author-name {
  margin-right: 10px;
}
}

.feedback-date {
color: #ADAFAF;
font-size: 12px;
}

.feedback-custom-fields {
padding: 15px;
border: 2px dashed rgba($primaryColor, 0.2);
background: rgba($primaryColor, 0.06);
margin: 10px 0;
> li {
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.value {
  display: block;
  font-style: italic;
  &::before {
    content: '-';
  }
  .comment {
    &::before {
      content: '.';
      margin-left: -3px;
    }
  }
}
}
&.feedback-item-small {
.feedback-content  {
  width: 100%;
}
}
}

.modal-sm-450 {
width: 450px;
}

.product-discount-selector {
text-align: center;
margin-bottom: 25px;
user-select: none;

span {
display: inline-block;
}
.control {
$size: 26px;
@include mobile(){
  $size: 36px;
}
font-size: 18px;
@include round($size);
line-height: $size;
background-color: darken($primaryColor, 15%);
color: #fff;
text-align: center;
@include ease(.3s);
cursor: pointer;
&:hover {
  background-color: lighten($primaryColor, 15%);
}
}

.value-group{
margin: 0 10px;
font-size: 20px;
}
}

.ban-page {
.ban-page-header {
background-size: cover;
background-position: 50%;
background-repeat: no-repeat;
height: 40vh;
@include mobile(){
  height: 25vh;
}
}
}

.cart-item {
position: relative;
@include ease(.2s);
.product-action-remove {
position: absolute;
left: -5px;
top: -5px;
display: inline-block;
@include round(24px);
line-height: 24px;
background: $secondaryColor;
color: #fff;
text-align: center;
z-index: 2;
opacity: 0;
transform: scale(0);
@include ease(.2s);
}
&:hover {
.product-action-remove {
  opacity: 1;
  transform: scale(1);
}
}

.removed-placeholder {
@include absoluteAll();
display: none;
align-items: center;
justify-content: center;
font-size: 16px;
font-weight: bold;
}

&.removed{
.product-action-remove {
  display: none;
}
article{
  opacity: .2;
}
.removed-placeholder {
  display: flex;
}
}
}

@include mobile()
{
.pac-container {
.pac-item {
  padding: 10px;
  font-size: 15px;
}
.pac-icon {
  display: none;
}
}
.pac-logo:after {
display: none;
}
}

.dropdown-tab-toggler {
display: none;
@include mobile(){
display: inline-block;
height: 40px;
line-height: 40px;
width: 100%;
padding: 0 10px;
font-size: 15px;
margin-bottom: 10px;
border: 1px solid $bordersColor;
}
}

.mobile-navbar {
.navbar-toggle {
border: none;
&:hover, &:focus {
  background: transparent;
}
.icon-bar {
  width: 26px;
  height: 3px;
}
}
.navbar-profile {
text-align: center;
padding: 25px 0 0 0;
margin-bottom:15px;
.user-avatar{
  display: block;
  margin: 0 auto 20px auto;
  position: relative;
  .image{
    display: inline-block;
    position: relative;
  }
}
.unread-notifications {
  position: absolute;
  right: -5px;
  top: -5px;
}
img {
  max-width: 80px;
  border-radius: 50%;
}
}
@include desktop(){
display: none;
}
.select-language {
padding: 15px;
text-align: center;
box-sizing: border-box;
}
.pick-language {
@mixin languagePickerItem(){
  border: 1px solid $bordersColor;
  font-size: 13px;
  border-radius: 50%;
  display: inline-block;
  @include round(30px);
  line-height: 30px;
  text-align: center;
  text-decoration: none;
}
@include languagePickerItem();
position: absolute;
right: $sitePadding;
top: $sitePadding;
.dropdown-menu {
  padding: 0;
  box-shadow: none;
  border: none;
  li {
    a{
      padding: 0;
      @include languagePickerItem();
    }
  }
}
}
}

.mobile-nav{
padding-left: 15px;
> li {
display: block;
border-bottom: 1px solid rgba(200, 200, 200, .3);
a {
  display: block;
  padding: 10px 0;
  color: $primaryColor;
  font-size: 16px;
  text-decoration: none;
}
}
.unread-notifications, .unread-count{
float: right;
$size: 26px;
font-size: 11px;
@include round($size);
line-height: $size;
}
}

.mobile-login-form {
padding: 50px 15px 0 15px;
.btn-main {
display: block;
width: 100%;
box-sizing: border-box;
border-radius: 0;
&.login {
  margin-bottom: 15px;
}
&.register {
  background-color: $primaryColor;
  border-color: $primaryColor;
}
}
.form-group{
input[type="text"],
input[type="email"],
input[type="password"]{
  height:50px;
  line-height:50px;
  border-radius:0;
  font-size: 16px;
}
}
}

.btn-main {
&[disabled],
&[disabled]:hover,
&[disabled]:focus {
background: rgba(200, 200, 200, 1);
border-color: rgba(200, 200, 200, 1);
}
&.diabled-on-tc {
@include ease(.2s);
}
}

.option-two-passenger-disclaimer {
display: none;
}

.landing-welcome {
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
position: relative;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
.landing-inner {
display: block;
width: 700px;
text-align: center;
z-index: 2;
.inner-content {
  position: relative;
  color: #fff;
  z-index: 3;
}

.title {
  font-size: 42px;
  margin-bottom: 40px;
}
}

.logo {
margin-bottom: 30px;
}

.counter-down {
margin-bottom: 40px;
}

.logo-block {
img {
  margin-bottom: 0;
  width: 125px;
}
.logo-caption {
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 8rem;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: bold;
}
}

.time-remaining {
display: flex;
justify-content: space-between;
max-width: 360px;
align-items: center;
margin: 0 auto 20px auto;
li {
  display: inline-block;
  .time {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    text-transform: uppercase;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
}
}

.landing-date-desc {
font-size: 18px;
margin-bottom: 40px;
}

.dismiss-btn {
display: inline-block;
height: 40px;
line-height: 40px;
padding: 0  30px;
text-transform: uppercase;
font-size: 13px;
background: #5cb646;
letter-spacing: 2px;
color: #fff;
text-decoration: none;
transition: .3s ease;
&:hover {
  text-decoration: none;
  opacity: .8;
}
}

.video-bg {
position: absolute;
top: 0px;
left: 0px;
bottom: 0px;
right: 0px;
overflow: hidden;
background-size: cover;
background-color: rgb(51, 51, 51);
background-repeat: no-repeat;
background-position: 50% 50%;
background-image: none;
z-index: 1!important;
video {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  opacity: 1;
  width: 1922px;
  height: auto;
  z-index: 1!important;
}
}
.aside-social {
position: absolute;
right: 40px;
bottom: 40px;
z-index: 3;
.links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    a {
      display: inline-block;
      height: 50px;
      width: 50px;
      line-height: 50px;
      color: #fff;
      border-radius: 50%;
      text-align: center;
      font-size: 18px;
      transition: .3s ease;
      &.facebook {
        background-color: #3b5999;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
    &.facebook {
      transform: translateX(100px);
      opacity: 0;
      transition: .6s ease;
    }
  }
}
}

.copyright {
position: absolute;
bottom: 5px;
z-index: 3;
font-size: 12px;
color: rgba(244, 244, 244, .45);
.sep {
  margin: 0 4px;
}
}

@media(max-width: 1300px) {
.logo-block {
  img {
    width: 100px;
  }
  .logo-caption {
    font-size: 20px;
    margin-bottom: 5rem;
  }
}
.landing-inner {
  padding: 20px;
}

.title {
  font-size: 36px;
  margin-bottom: 25px;
}

.time-remaining {
  li {
    .time {
      font-size: 28px;
    }
    span {
      font-size: 14px;
    }
  }
}

.dismiss-btn {
  height: 36px;
  line-height: 36px;
  padding: 0 25px;
  margin-bottom: 10px;
}
.aside-social {
  bottom: auto;
  top: 20px;
  right: 20px;
}
.copyright {
  padding: 0 20px;
}
}
}

.welcome-overlay-bg {
display: block;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background-color: rgba(0, 0, 0, .35);
background-image: url(../img/overlay.png);
}

body.loaded {
.landing-welcome {
.aside-social {
  .links {
    li {
      &.facebook {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
}
}
}

.form-group,
.auth-group-page {
.disclaimer {
margin: 5px 0;
font-size: 12px;
}
}

.ride-create-address-addon {
min-width: 100px;
text-align: left;
i {
color: $primaryColor;
}
}

.widget-partners {
.partners-list {
li {
  margin-bottom: 10px;
  img {
    max-height: 22px;
    width: auto;
  }
}
}
}

.homepage-title {
margin-bottom: 30px;
}

.static-page-header,
.search-page--header{
  padding: 20px 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  .page-title {
    font-size: 24px;
    color: #36334A;
    margin-bottom: 15px;
  }

  .form-control {
    height: 40px;
    line-height: 40px;
  }

  .search-button {
    height: 40px;
    line-height: 40px;
    border-radius: 0;
  }

  @media(max-width: 1200px) {
    .date-group {
      .inline-block-input{
        width: 50% !important;
      }
    }
  }

  @include mobile() {
    .date-group {
      .inline-block-input{
        width: 100% !important;
        margin-bottom: 20px;
      }

      button {
        width: 84% !important;
        float: none !important;
      }
    }
  }
}

.dropdown {
&.user {
.unread-notifications{
  transition: .3s ease;
}
&:hover, &.open {
  .unread-notifications{
    transform: scale(1.1);
    background-color: lighten( $secondaryColor, 5% );
  }
}
}
}

.request-confirmation-ajax {
transition: .3s ease;
i{
display: none;
}
&.sending {
i {
  display: inline-block;
}
}
}

.unconfirmed-panel {
.panel-body {
padding: 15px !important;
}
}

.small-box {
&.bg-aqua {
background-color: #8297a2 !important;
}
}

.driver-feedback-on-passengers {
.confirm-ride-finished-block:not(.default) {
padding: 20px;
text-align: left;
}
.leave-feedback-title {
margin: 0 0 15px 0;
}
.passengers-list--list {
list-style-type: none;
.passenger {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  img {
    display: inline-block;
    max-width: 35px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .booking-details-block {
    display: inline-block;
  }
  .passenger-inner {
    display: flex;
    align-items: center;
    position: relative;
  }
  .tab-toggler {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .booking-metas {
    list-style-type: none;
    li {
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      &:not(:last-child) {
        &::after {
          content: '';
          display: inline-block;
          height: 3px;
          width: 3px;
          border-radius: 50%;
          background: rgba(200, 200, 200, .6);
          margin: 0 5px;
          vertical-align: middle;
        }
      }
    }
  }
}
}
}

.lang-dropdown-simple {
li {
display: none;
&.current {
  display: block;
}
a {
  i {
    transform: rotate(90deg);
    display: inline-block;
    font-size: 80%;
  }
}
}
&:hover {
li {
  display: block;
}
}
}

.landing-welcome {
.aside-language {
position: fixed;
top: 20px;
right: 20px;
z-index: 3;
.lang-dropdown-simple {
  display: block;
  width: 100px;
  a {
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
    &:hover, &:active, &:focus {
      text-decoration: none;
      color: $primaryColor;
    }
  }
}
@include mobile()
{
  right: auto;
  left: 20px;
}
}
}

.navbar-lang-picker {
float: right;
position: relative;
width: 100px;
height: 50px;
padding-top: 17px;
.lang-dropdown-simple {
position: absolute;
li {
  a {
    transition: .3s ease;
    &:hover {
      text-decoration: none;
      color: lighten( $primaryColor, 5% );
    }
  }
}
}
}

.landing-welcome {
transition: .5s ease;

.dismiss {
margin-bottom: 40px;
}

@include mobile()
{
.copyright {
  display: none;
}
}
}


.landing-welcome-screen2{
.latest-rides {
margin: 30px auto 0 auto;
padding: 0 15px;
@include desktop()
{
  max-width: 870px;
  transform: translateX(7%);
}
}

.latest-rides-title {
margin: 30px 0 20px 0;
font-size: 32px;
color: $primaryColor;
@include mobile(){
  font-size: 28px;
  margin-top: 20px;
}
}

.dismiss-btn {
display: inline-block;
height: 40px;
line-height: 40px;
padding: 0  30px;
text-transform: uppercase;
font-size: 13px;
background: #5cb646;
letter-spacing: 2px;
color: #fff;
text-decoration: none;
transition: .3s ease;
&:hover {
  text-decoration: none;
  opacity: .8;
}
}

.dismiss {
padding: 20px 0;
text-align: center;
}
}
.ride-entry-style2 {
text-align: left;
display: block;
background: #fefefe;
border: 1px solid rgba(200, 200, 200, .3);
margin-bottom: 20px;

.departure-datetime {
margin-bottom: 10px;
}

.route-details {
font-size: 22px;
color: #333;
display: flex;
align-items: center;
}

.route-sep {
display: flex;
align-items: center;
flex-direction: row;
min-width: 40px;
margin: 0 5px;
}

.dot {
display: inline-block;
height: 3px;
width: 3px;
margin-right: 4px;
border-radius: 50%;

&.dot1 {
  background-color: #EFF6FF;
}
&.dot2 {
  background-color: #D0E5FF;
}
&.dot3 {
  background-color: #B8D7FF;
}
&.dot4 {
  background-color: #A1CBFF;
}
&.dot5 {
  background-color: #8BBEFF;
}
&.dot6 {
  background-color: #5AA3FF;
}

}

.ride--loop--footer{
margin-top: 25px;
> .row {
  display: flex;
  align-items: center;
}

.book-btn {
  .btn-primary {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 22px;
    border-radius: 0;
  }
}
}

.ride-loop-waypoints--holder {
display: flex;
align-items: center;
.wpts-info {
  color: #777777;
  font-size: 11px;
  margin-right: 5px;
}
.route-sep{
  min-width: 20px;
  .dot {
    margin-right: 3px;
  }
}
.waypoints-list {
  font-size: 15px;
  color: #555;
  display: flex;
  align-items: center;
  .wpt {
    display: flex;
  }

  > span {
    &:last-child {
      .route-sep{
        display: none;
      }
    }
  }
}
}


.profile-card {
.avatar {
  max-width: 40px;
}
figcaption {
  .role, .age {
    font-size: 13px;
  }
  .name {
    font-size: 14px;
  }
}
}

.price {
font-size: 26px;
margin-top: -5px;
text-align: right;
.desc {
  font-size: 12px;
}
}

.free-seats {
font-size: 15px;
}

.departure-datetime {
font-size: 16px;
}

.departure-location, .arrival-location, .waypoint-location {
font-size: 14px;
padding: 0;
}

@include mobile()
{
display: block;
height: auto !important;
margin: 0;
margin-bottom: 30px;

.route-details {
  display: block;
  position: relative;
  .route-sep {
    position: absolute;
    left: -33px;
    top: 50%;
    transform: rotate(90deg) translateY(-50%);
  }
  .route-details--departure{
    margin-bottom: 10px;
  }
  .route-details--departure, .route-details--arival {
    display: block;
  }
}

.ride-loop-waypoints--holder{
  display: none;
}
}
}


.profile-card.main-profilecard {
position: relative;
.chat-actions {
position: absolute;
top: 0;
right: 0;
}
}

.btn-primary.instant-chat-toggler{
i {
color: #fff;
}
}


.latest-rides {
@include mobile()
{
padding: 0 !important;
margin: 0 0 0 0 !important;
}
}

body.loaded {
.landing-welcome-screen2 {
.latest-rides {
  opacity: 1;
}
}
}

.ride-entry2-wrap {
position: relative;
display: block !important;
}

.sharing-panel {
.panel-body {
padding: 15px !important;
}
}

.sharing-links {
// text-align: center;
margin-bottom: 0;
li {
display: inline-block;
width: 30%;
vertical-align: top;
@include mobile() {
  width: 100%;
}
&:not(:last-child) {
  margin-right: 10px;
  @include mobile() {
    margin-bottom: 10px;
  }
}
.share-item {
  display: inline-block;
  padding: 10px 15px;
  color: #fff;
  transition: .5s ease;
  // width: 100%;
  // width: 42px;
  // height: 42px;
  // line-height: 42px;
  text-align: left;
  width: 100%;
  font-size: 14px;
  i {
    margin-right: 7px;
    font-size: 15px;
  }
  .addon {
    // display: none;
  }
  &.share-item-fb {
    background: #3b5999;
    &:hover {
      background: darken( #3b5999, 10% );
    }
  }
  &.share-item-vk {
    background: #4c75a3;
    &:hover {
      background: darken( #4c75a3, 10% );
    }
  }
  &.share-item-ok {
    background: #ed812b;
    &:hover {
      background: darken( #ed812b, 10% );
    }
  }
  &:hover {
    text-decoration: none;
  }
}
}
}


.no-pad-right {
padding-right: 0;
}

.no-pad-left {
padding-left: 0;
}

.callaction-wide {
min-height: 400px;
background-image:url('/img/callaction.jpg');
background-size: contain;
background-position: center;
background-repeat: repeat-x;
position: relative;
display: flex;
align-items: center;

&::before {
content: ' ';
display: block;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background: rgba(0, 0, 0, 0.04);
}

.callaction-content {
color: #333;
position: relative;
.callaction-title {
  font-weight: normal;
  font-size: 36px !important;
  margin-bottom: 0;
}
.callaction-title-secondary {
  font-weight: lighter;
  font-size: 30px !important;
  opacity: 0.8;
  margin-top: 0;
  margin-bottom: 20px;
}
.callaction-text {
  margin-bottom: 40px;
}
}

}

.float-right {
float: right;
}

.btn-material {
border-radius: 0;
padding-left: 40px;
padding-right: 40px;
text-transform: uppercase;
font-weight: bold;
letter-spacing: 1px;
margin-right: 0;
}

.estimate-cost--section {
padding: 80px 0;
.subtitle {
font-size: 22px;
margin-bottom: 5px;
}

.title {
margin-top: 0;
margin-bottom: 20px;
}

input.maps-autocomplete {
padding-left: 35px;
}

.button {
color: #fff;
max-width: 150px;
border-radius: 0;
float: right;
}
}

.flex-row--center {
display: flex;
align-items: center;
}




.home-hero {
margin-bottom: 30px;
.slider-item {
outline: none;
.row {
  display: flex;
  align-items: center;
}

.slide-title, .slide-description {
  margin-bottom: 20px;
}
img {
  width: 100%;
  height: auto;
}
}
}
.home-header.site-title {
margin: 30px 0 40px 0;
}

.site-section--secondary {
background: #f5f5f5;
}

.site-section--travel {
  padding-bottom: 50px;
}

.page-viewport {
height: 75vh;
}

.tiny-modal {
position: fixed;
top: 50%;
left: 50%;
border: 1px solid rgba(200, 200, 200, .4);
border-radius: 15px;
transform: translate(-50%);
min-width: 640px;
min-height: 300px;
background-color: #fff;
display: flex;
justify-content: center;
align-items: center;
.tiny-modal--body {
text-align: center;
}

.tiny-modal--title {
margin-bottom: 25px;
}

.tiny-modal--icon {
img {
  height: 100px;
  width: auto;
  margin-bottom: 30px;
}
}
}
.ride-share-modal {
.sharing-links {
li {
  width: auto;
  display: inline-block;
  .share-item {
    height: 38px;
    width: 38px;
    line-height: 38px;
    border: none;
    border-radius: 50%;
    padding: 0;
    text-align: center;
    transition: .3s ease;
    i {
      margin-right: 0;
    }

    &:hover {
      transform: scale(1.06);
    }
  }
}
}
}



.main-title {
font-size: 28px;
.le-blue {
color: #72C9EF;
font-weight: bold;
}
}

.related--cities {
margin-bottom: 20px;
.rel-cities--title {
font-size: 13px;
color: #777;
margin-right: 10px;
}

.rel-city-pill {
display: inline-block;
height: 30px;
line-height: 30px;
padding: 0 25px;
border-radius: 25px;
color: #fff;
transition: .3s ease;
text-decoration: none;
margin-right:15px;
&:hover {
  opacity: .8;
}
}
}

.pill-green {
background-color: #22D87C;
color: #fff;
}

.pill-blue {
background: #32BFEB;
color: #fff;
}


.main-site-nav {
padding: 10px 0;
.navbar-nav .nav-link {
text-transform: uppercase;
font-size: 13px;

&.btn-material-prime {
  color: #fff;
  margin-left: 25px;
  margin-top: -3px;
}
}
}

.disclaimer-body {
  margin: 10px 0;
  padding: 15px;
  border: 1px solid rgba(0, 200, 10, 0.3);

  &.smaller{
    h5 {
      font-size: 13px;
    }
    font-size: 10px;
  }
}

.site-footer {
padding: 10px 0;
background: #f5f5f5;
color: #333;
}


.services-item,
.destination-item {

padding: 15px;
background: #fff;
border-radius: 9px;
font-size: 18px;
color: #333;
display: flex;
align-items: center;
flex-direction: row;
justify-content: space-between;
position: relative;
transition: .3s ease;

&:hover{

}

.cities{
  width: 70%;

  .destination-item--to{
    font-weight: bold;
  }
}

.price{
  width: 30%;
  font-weight: bold;
  color: #4285f4;
  display: flex;
  flex-direction: column;

  .starting-at {
    font-size: 13px;
  }
}

.abs-link {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
}
}

.services-item{
.services-item--title {
width: 65%;
font-weight: bold;
}
.image {
width: 35%;
text-align: right;
display: flex;
align-items: center;
justify-content: flex-end;
img {
  height: 50px;
  width: auto;
}
}
}
.services-boxes,
.destination-boxes{
padding-top: 44px;
@media(max-width: 768px) {
flex-direction: column;
> div {
  width: 100%;
}

.destination-item, .services-item {
  margin-bottom: 20px;
}
}
}
//mobile
@media(max-width: 768px){
.estimate-cost--section{
padding: 30px 0;

.subtitle {
  margin-top: 0;
}

.flex-row--center .col-md-7 {
  display: none !important;
}
}

.services-boxes, .destination-boxes{
padding-top: 20px;
}

.icon-box{
margin-bottom: 30px;
}
}

.airhelp--page{
min-height: 55vh;
background-image: url("/img/airhelp-bg.jpg");
background-size: cover;
background-position: center;
display: flex;
align-items: center;

.airhelp-logo{
max-width: 130px;
display: inline-block;
img {
  max-width: 100%;
  height: auto;
}
}

h1 {
margin-top: 10px;
margin-bottom: 25px;
}

h4 {
font-size: 22px;
margin-bottom: 25px;
}

.btn {
border-radius: 0;
}
}


.airhelp--page-content{
.page-header{
text-align: center;
max-width: 50%;
margin: 0 auto 30px auto;

.section-subtitle{
  font-size: 15px;
  line-height: 1.5;
}
}

.tab-pane{
padding: 0 20px 0 40px;
line-height: 1.7;
font-size: 15px;

.know-more {
  display: block;
  margin: 10px 0;
  text-decoration: none;
  font-size: 16px;
}
}

.rights-tabs{
display: flex;
flex-direction: row;
.nav {
  width: 20%;
  li {
    display: block;
    width: 100%;
    border:1px solid rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s ease-in-out;
    a{
      font-weight: 600;
      cursor: pointer;
      color: #536273;
      font-size: 17px;
      border:1px solid rgba(0, 0, 0, 0);
      &:hover {
        border:1px solid rgba(0, 0, 0, 0);
        background: transparent;
        color: #333;
      }
    }
  }
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
  border:1px solid rgba(0, 0, 0, 0);
  box-shadow: 0 8px 35px 0 rgba(50, 50, 93, 0.1), 0 5px 15px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
}

.tab-content{
  width: 70%;
}
}
}




/**
 * @Version 2
 * @Overwrite
 */


.auth-group-page {
  .content-section {
    max-width: 70%;
    min-height: 450px;

    .subtitle {
      margin-bottom: 100px;
    }
  }

  .site-section--secondary {
    background: #fff;
    .services-item {
      border: 1px solid rgba(200, 200, 200, .5);
    }
  }
}


.auth-page-hero {
  display: block;
  position: relative;
  min-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
  }

  .auth-page-hero-content {
    color: #fff;
    position: relative;
    line-height: 1.7;

    .auth-page-headings {
      max-width: 65%;
    }

    .auth-page-hero-title {
      margin-top: 100px;
      font-size: 38px;
      margin-bottom: 15px;
      line-height: 1.7;
    }

    .auth-page-hero-subtitle {
      font-size: 18px;
      line-height: 1.7;
    }
  }


  .form-panel {
    position: absolute;
    right: 5%;
    top: 2%;
    max-width: 500px;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    z-index: 99;
  }
}

.icon-box--xs-icon {
  img {
    max-width: 100%;
    width: auto;
    height: 60px;
    margin-bottom: 40px;
  }
}

.confirm-page.auth-group-page {
  .auth-page-hero {
    .form-panel {
      top: 12%;
    }
  }
}

.addcar-page {
  .addcar-panel {
    backround-color: #fff;
    position: relative;
    padding: 30px;

    .upload-image {
      margin-bottom: 50px;
    }

    .submit-row {
      margin-top: 50px;
    }
  }
}


.dashboard-box-item.dashboard-box-item--sm {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 8px;
  background-color: #f5f5f5;
  padding: 15px 20px;
  width: 100%;
  margin-bottom: 10px;
  transition: .3s ease;

  &:hover {
    background-color: darken(#f5f5f5, 5%);
  }

  .dashboard-box-item-icon {
    img {
      width: 32px;
      height: 32px;
    }
  }

  .dashboard-box-item-content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding-left: 20px;
    font-size: 16px;
    color: #36334A;

    span {
      display: flex;
    }

    .box-item-value {
      padding-left: 15px;
      border-left: 1px solid #d0d0d0;
      font-weight: bold;
    }
  }
}


@media(max-width: 768px) {
  .auth-page-hero .auth-page-hero-content .auth-page-hero-title {
    margin-top: 30px;
  }
  .auth-page-hero {
    .form-panel {
      left: 0;
      right: 0;
      top: 15px;
      position: relative;
      max-width: 100%;
      box-shadow: none;
      padding: 15px;
    }

    .auth-page-hero-content {
      .auth-page-headings {
        max-width: 100%;
      }
    }
  }

  .auth-group-page .content-section {
    display: none !important;
  }

  .confirm-page {
    .auth-page-hero {
      display: flex;
      flex-direction: column-reverse;
      padding: 20px 0;

      .form-panel {
        top: 0;
        margin: 10px;

        .form-group {
          input + .desc {
            margin-top: 10px;
          }

          .btn-main {
            width: 90%;
          }
        }
      }
    }
  }
}

.mobile-nav {
  bottom: 0;
  .nav-item {
    padding: 15px 0;
    border: 1px solid rgba(200, 200, 200, .5);
    margin-bottom: 20px;
    border-radius: 8px;
    background: #f5f5f5;
    .nav-link {
      display: flex;
      padding: 0 15px;
      align-items: center;
      img {
        width: 26px;
        height: auto;
        margin-right: 15px;
      }
    }
  }

  &.mobile-nav--unauth {
    margin-top: 20%;
  }
}

.unauth-logo-wrapper {
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.btn-darken {
  background-color: $dark-bg;
  color: $dark-bg-txt;

  &:hover, &:active {
    background-color: ligten($dark-bg, 10%);
    color: $dark-bg-txt;
  }
}

.bg-darken {
  background-color: $dark-bg;
  color: $dark-bg-txt;
}

@import "./components/components.scss";