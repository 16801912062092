.profile {
    &__tabs {
        .nav-pills {
            a,
            a:hover,
            a:focus {
                color: $dark-bg;
            }

            .active a,
            .active a:hover,
            .active a:focus {
                background-color: $dark-bg;
                color: $dark-bg-txt;
            }
        }
    }
}