@font-face {
  font-family: 'drumbun-icons';
  src: url('../fonts/drumbun-icons.eot?90414940');
  src: url('../fonts/drumbun-icons.eot?90414940#iefix') format('embedded-opentype'),
       url('../fonts/drumbun-icons.woff2?90414940') format('woff2'),
       url('../fonts/drumbun-icons.woff?90414940') format('woff'),
       url('../fonts/drumbun-icons.ttf?90414940') format('truetype'),
       url('../fonts/drumbun-icons.svg?90414940#drumbun-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'drumbun-icons';
    src: url('../font/drumbun-icons.svg?90414940#drumbun-icons') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "drumbun-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-heart:before { content: '\e800'; } /* '' */
.icon-star:before { content: '\e801'; } /* '' */
.icon-heart-empty:before { content: '\e802'; } /* '' */
.icon-star-empty:before { content: '\e803'; } /* '' */
.icon-user:before { content: '\e804'; } /* '' */
.icon-users:before { content: '\e805'; } /* '' */
.icon-picture:before { content: '\e806'; } /* '' */
.icon-camera:before { content: '\e807'; } /* '' */
.icon-camera-alt:before { content: '\e808'; } /* '' */
.icon-ok:before { content: '\e809'; } /* '' */
.icon-cancel:before { content: '\e80a'; } /* '' */
.icon-plus:before { content: '\e80b'; } /* '' */
.icon-minus-circled:before { content: '\e80c'; } /* '' */
.icon-plus-circled:before { content: '\e80d'; } /* '' */
.icon-thumbs-up:before { content: '\e80e'; } /* '' */
.icon-thumbs-down:before { content: '\e80f'; } /* '' */
.icon-download:before { content: '\e810'; } /* '' */
.icon-upload:before { content: '\e811'; } /* '' */
.icon-home:before { content: '\e812'; } /* '' */
.icon-link:before { content: '\e813'; } /* '' */
.icon-pencil:before { content: '\e814'; } /* '' */
.icon-edit:before { content: '\e815'; } /* '' */
.icon-comment:before { content: '\e816'; } /* '' */
.icon-chat:before { content: '\e817'; } /* '' */
.icon-bell:before { content: '\e818'; } /* '' */
.icon-location:before { content: '\e819'; } /* '' */
.icon-folder:before { content: '\e81a'; } /* '' */
.icon-folder-open:before { content: '\e81b'; } /* '' */
.icon-phone:before { content: '\e81c'; } /* '' */
.icon-cog:before { content: '\e81d'; } /* '' */
.icon-cog-alt:before { content: '\e81e'; } /* '' */
.icon-wrench:before { content: '\e81f'; } /* '' */
.icon-basket:before { content: '\e820'; } /* '' */
.icon-calendar:before { content: '\e821'; } /* '' */
.icon-login:before { content: '\e822'; } /* '' */
.icon-logout:before { content: '\e823'; } /* '' */
.icon-volume-up:before { content: '\e824'; } /* '' */
.icon-volume-down:before { content: '\e825'; } /* '' */
.icon-volume-off:before { content: '\e826'; } /* '' */
.icon-block:before { content: '\e827'; } /* '' */
.icon-zoom-in:before { content: '\e828'; } /* '' */
.icon-zoom-out:before { content: '\e829'; } /* '' */
.icon-left-big:before { content: '\e82a'; } /* '' */
.icon-right-big:before { content: '\e82b'; } /* '' */
.icon-right-open:before { content: '\e82c'; } /* '' */
.icon-left-open:before { content: '\e82d'; } /* '' */
.icon-arrows-cw:before { content: '\e82e'; } /* '' */
.icon-award:before { content: '\e82f'; } /* '' */
.icon-inbox:before { content: '\e830'; } /* '' */
.icon-globe:before { content: '\e831'; } /* '' */
.icon-leaf:before { content: '\e832'; } /* '' */
.icon-road:before { content: '\e833'; } /* '' */
.icon-truck:before { content: '\e834'; } /* '' */
.icon-user-1:before { content: '\e835'; } /* '' */
.icon-users-1:before { content: '\e836'; } /* '' */
.icon-user-add:before { content: '\e837'; } /* '' */
.icon-search:before { content: '\e838'; } /* '' */
.icon-mail:before { content: '\e839'; } /* '' */
.icon-flag:before { content: '\e83a'; } /* '' */
.icon-help:before { content: '\e83b'; } /* '' */
.icon-plus-1:before { content: '\e83c'; } /* '' */
.icon-check:before { content: '\e83d'; } /* '' */
.icon-cancel-1:before { content: '\e83e'; } /* '' */
.icon-right-thin:before { content: '\e83f'; } /* '' */
.icon-left-thin:before { content: '\e840'; } /* '' */
.icon-trophy:before { content: '\e841'; } /* '' */
.icon-lock:before { content: '\e842'; } /* '' */
.icon-lock-open:before { content: '\e843'; } /* '' */
.icon-thumbs-up-1:before { content: '\e844'; } /* '' */
.icon-thumbs-down-1:before { content: '\e845'; } /* '' */
.icon-attention:before { content: '\e846'; } /* '' */
.icon-suitcase:before { content: '\e847'; } /* '' */
.icon-briefcase:before { content: '\e848'; } /* '' */
.icon-gauge-1:before { content: '\e849'; } /* '' */
.icon-vk:before { content: '\e84a'; } /* '' */
.icon-call:before { content: '\e84b'; } /* '' */
.icon-odnoklassniki:before { content: '\e84c'; } /* '' */
.icon-ok-1:before { content: '\e84d'; } /* '' */
.icon-cancel-2:before { content: '\e84e'; } /* '' */
.icon-phone-squared:before { content: '\f098'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-money:before { content: '\f0d6'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-gauge:before { content: '\f0e4'; } /* '' */
.icon-comment-empty:before { content: '\f0e5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-lightbulb:before { content: '\f0eb'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-coffee:before { content: '\f0f4'; } /* '' */
.icon-food:before { content: '\f0f5'; } /* '' */
.icon-plus-squared:before { content: '\f0fe'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-folder-empty:before { content: '\f114'; } /* '' */
.icon-folder-open-empty:before { content: '\f115'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-frown:before { content: '\f119'; } /* '' */
.icon-meh:before { content: '\f11a'; } /* '' */
.icon-flag-empty:before { content: '\f11d'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */
.icon-shield:before { content: '\f132'; } /* '' */
.icon-minus-squared:before { content: '\f146'; } /* '' */
.icon-minus-squared-alt:before { content: '\f147'; } /* '' */
.icon-euro:before { content: '\f153'; } /* '' */
.icon-pound:before { content: '\f154'; } /* '' */
.icon-dollar:before { content: '\f155'; } /* '' */
.icon-rupee:before { content: '\f156'; } /* '' */
.icon-yen:before { content: '\f157'; } /* '' */
.icon-rouble:before { content: '\f158'; } /* '' */
.icon-bitcoin:before { content: '\f15a'; } /* '' */
.icon-thumbs-up-alt:before { content: '\f164'; } /* '' */
.icon-thumbs-down-alt:before { content: '\f165'; } /* '' */
.icon-left:before { content: '\f177'; } /* '' */
.icon-right:before { content: '\f178'; } /* '' */
.icon-plus-squared-alt:before { content: '\f196'; } /* '' */
.icon-cubes:before { content: '\f1b3'; } /* '' */
.icon-cab:before { content: '\f1b9'; } /* '' */
.icon-taxi:before { content: '\f1ba'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-paypal:before { content: '\f1ed'; } /* '' */
.icon-bell-off:before { content: '\f1f6'; } /* '' */
.icon-bell-off-empty:before { content: '\f1f7'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-bicycle:before { content: '\f206'; } /* '' */
.icon-bus:before { content: '\f207'; } /* '' */
.icon-motorcycle:before { content: '\f21c'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-train:before { content: '\f238'; } /* '' */
.icon-subway:before { content: '\f239'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */
.icon-linkedin-squared:before { content: '\f30c'; } /* '' */
.icon-odnoklassniki-rect:before { content: '\f30d'; } /* '' */